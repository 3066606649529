import { Link } from "react-router-dom";
const AuthHeaders = ({ position }) => {
  return (
    <div
      className={`${position} w-full bg-white border-b h-[80px] flex items-center px-3 md:px-8`}
    >
      <Link to={"/"}>
        <span className="font-bold text-xl text-primary">AGFoods</span>
      </Link>
    </div>
  );
};

export default AuthHeaders;
