import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import NavigationHeader from "../../../Components/navigationHeader";
import Modal from "../../../Components/modals";
import { useDeleteInvestmentType, useInvestmentTypeInvestors, useInvestmentTypeList } from "../adminLayout/reusableEffect";
import comingSoon from "../../../assets/icons/comingSoon.svg";
import searchIcon from "../../../assets/icons/search.svg";
import investorsIcon from "../../../assets/icons/customers.svg"
import Spinner from "../../../Components/spinner";
import { useDispatch, useSelector } from "react-redux";
import { SearchTable, filterTable } from "../../../Utils/utils";
import InputWithLabel from "../../../Components/inputWithLabel";
import CurrencyInput from "../../../Components/currencyInput";
import Buttons from "../../../Components/buttons";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { addNewInvestment, changeInvestmentTypeStatus, investmentRollOver } from "../../../hooks/local/adminReducer";

const SingleInvestmentType = ({ setPageTitle }) => {
  const fileInputRef = useRef(null);
  useEffect(() => {
    setPageTitle("Investments");
    document.title = "Investments | Agro-Allied";
    document.querySelector('meta[name="description"]').content = "Explore investment opportunities, manage your investments, and monitor your portfolio seamlessly with Agro-Allied.";
  }, [setPageTitle]);
  let { investmentId, investmentName } = useParams();
  investmentId = atob(investmentId);
  investmentName = atob(investmentName);
  const [tabVisibility, setTabVisibility] = useState({ investmentTab: true, investorsTab: false });
  const showTab = (tabId) => {
    setTabVisibility(() => ({
      investmentTab: tabId === 'investmentTab',
      investorsTab: tabId === 'investorsTab',
    }))
  }
  const dispatch = useDispatch();

  const deleteInvestmentType = useDeleteInvestmentType();
  const handleDeleteInvestment = async () => {
    await deleteInvestmentType(investmentId);
  };

  const changeInvestmentStatus = async (investmentId) => {
    try {
      const { payload } = await dispatch(changeInvestmentTypeStatus(investmentId))
      if (payload.statusCode === "200") {
        setSelectedInvestmentType(null);
      }
    }
    catch (error) { };
  }


  const listInvestmentType = useInvestmentTypeList(investmentId);
  const investorList = useInvestmentTypeInvestors(investmentId);
  const [deleteInvestmentModal, setDeleteInvestmentModal] = useState(false);
  const [createInvestmentModal, setCreateInvestmentModal] = useState(false);
  const [selectedInvestmentType, setSelectedInvestmentType] = useState(null);
  const [investmentPicture, setInvestmentPicture] = useState(null);
  const [selectedFileError, setSelectedFileError] = useState(false);
  const [rolloverInvestmentModal, setRolloverInvestmentModal] = useState(false);
  const handleInvestmentPictureUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setInvestmentPicture(file);
      setSelectedFileError(false);
    }
    else {
      e.target.value = null;
      setSelectedFileError(true);
    }
  }
  const createInvestmentForm = useFormik({
    initialValues: {
      maximumInvestment: "",
      minimumInvestment: "",
      totalInvestment: "",
      roiPercentage: "",
      noOfDays: "",
      startDate: "",
      investmentName: "",
      description: "",
    },
    validationSchema: Yup.object({
      maximumInvestment: Yup.string().required("Maximum Investment cannot be empty"),
      minimumInvestment: Yup.string().required("Minimum Investment cannot be empty"),
      totalInvestment: Yup.string().required("Total Investment cannot be empty"),
      roiPercentage: Yup.string().required("ROI Percentage cannot be empty"),
      noOfDays: Yup.string().required("No of days cannot be empty"),
      startDate: Yup.string().required("Start Date cannot be empty"),
      investmentName: Yup.string().required("Investment Name cannot be empty"),
      description: Yup.string().required("Description cannot be empty")
    }),
    onSubmit: async (values, { resetForm }) => {
      if (!investmentPicture) {
        setSelectedFileError(true);
        return;
      }
      const investmentImage = investmentPicture;
      const investmentTypeId = investmentId;
      const { maximumInvestment, minimumInvestment, totalInvestment, roiPercentage, noOfDays, startDate, investmentName, description } = values;
      let investmentData = { investmentTypeId, maximumInvestment, minimumInvestment, totalInvestment, roiPercentage, noOfDays, startDate, investmentName, description, investmentImage };
      const { payload } = await dispatch(addNewInvestment(investmentData));
      if (payload.statusCode === "200") {
        resetForm();
        setCreateInvestmentModal(false);
        window.location.reload();
      }
    }
  })

  const rollOverInvestmentForm = useFormik({
    initialValues: {
      investmentName: selectedInvestmentType?.investmentName || "",
      maximumInvestment: selectedInvestmentType?.maximumInvestment || "",
      minimumInvestment: selectedInvestmentType?.minimumInvestment || "",
      totalInvestment: selectedInvestmentType?.totalAmount || "",
      roiPercentage: selectedInvestmentType?.roi || "",
      noOfDays: selectedInvestmentType?.noOfDays || "",
      startDate: selectedInvestmentType?.endDate || "",
      description: selectedInvestmentType?.description || "",
      investmentTypeId: selectedInvestmentType?.investmentTypeId || "",
      investmentId: selectedInvestmentType?.id || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      maximumInvestment: Yup.string().required("Maximum Investment cannot be empty"),
      minimumInvestment: Yup.string().required("Minimum Investment cannot be empty"),
      totalInvestment: Yup.string().required("Total Investment cannot be empty"),
      roiPercentage: Yup.string().required("ROI Percentage cannot be empty"),
      noOfDays: Yup.string().required("No of days cannot be empty"),
      startDate: Yup.string().required("Start Date cannot be empty"),
      investmentName: Yup.string().required("Investment Name cannot be empty"),
      description: Yup.string().required("Description cannot be empty")
    }),
    onSubmit: async (values, { resetForm }) => {
      const { investmentTypeId, investmentId, maximumInvestment, minimumInvestment, totalInvestment, roiPercentage, noOfDays, startDate, investmentName, description } = values;
      let investmentData = { investmentId, investmentTypeId, maximumInvestment, minimumInvestment, totalInvestment, roiPercentage, noOfDays, startDate, investmentName, description };
      const { payload } = await dispatch(investmentRollOver(investmentData));
      if (payload.statusCode === "200") {
        resetForm();
        setRolloverInvestmentModal(false);
      }
    }
  })

  return (
    <div className="col-span-10">
      <Spinner loading={useSelector((state) => state.admin).loading} />
      <div className="md:flex justify-between items-center">
        <NavigationHeader title={investmentName + " investment"} />
        <div className="mt-6 md:mt-0 flex gap-4 text-sm md:text-xs overflow-x-scroll whitespace-nowrap">
          <button
            className="bg-primary text-white rounded p-4"
            onClick={() => setCreateInvestmentModal(true)}
          >
            Add new cycle
          </button>
          <button
            className="bg-red-500 text-white rounded p-4"
            onClick={() => setDeleteInvestmentModal(true)}
          >
            Delete "{investmentName}" investment
          </button>
        </div>
      </div>

      <div className="flex gap-4 my-5">
        <span
          className={`py-3 px-5 text-xs rounded-md ${tabVisibility.investmentTab
            ? "bg-primary text-white cursor-default"
            : "border border-black/30 text-black/40 cursor-pointer"
            }`}
          id="investmentTab"
          onClick={() => showTab("investmentTab")}
        >
          Investment cycles
        </span>
        <span
          className={`py-3 px-5 text-xs rounded-md ${tabVisibility.investorsTab
            ? "bg-primary text-white cursor-default"
            : "border border-black/30 text-black/40 cursor-pointer"
            }`}
          id="investorsTab"
          onClick={() => showTab("investorsTab")}
        >
          Investors
        </span>
      </div>

      <div id="investmentTab"
        className={tabVisibility.investmentTab ? "" : "hidden"}
      >
        {listInvestmentType.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6">
            {listInvestmentType.map((val, key) => {
              return (
                <div className="grid bg-[#f8f8f8] p-2 rounded mb-6 " key={key}>
                  <div className="grid gap-4">
                    <img src={val.investmentImage} alt="" className="rounded object-cover w-full aspect-video" />
                    <div className="flex justify-between gap-1">
                      <span className="text-sm font-semibold text-primary">  {val.investmentName} </span>
                      <div className="flex items-center gap-1 text-xs">
                        <span >Status:</span>
                        <span className={`text-xs ${val.status === "Active" ? "text-primary" : "text-red-500"} font-medium`}>
                          {val.status}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 pt-4">
                    <button className="text-xs px-6 py-4 rounded-md bg-primary text-white w-full" onClick={() => setSelectedInvestmentType(val)}>
                      Manage cycle
                    </button>
                    <Link to={`/management/cycle_investors/${btoa(investmentId)}/${btoa(val.id)}/${btoa(val.investmentName)}/${btoa(val.roi)}`} className="text-xs px-6 rounded-md border-[1.5px] border-primary/80 w-fit h-full flex items-center">
                      <img alt="" src={investorsIcon} className="h-6" />
                    </Link>
                  </div>
                </div>
              );
            })}
            {selectedInvestmentType && (
              <div>
                <Modal
                  isVisible={selectedInvestmentType !== null}
                  onClose={() => setSelectedInvestmentType(null)}
                >
                  <div className="grid gap-2">
                    <div className="rounded-md overflow-hidden">
                      <img src={selectedInvestmentType.investmentImage} alt="" className="h-48 w-full object-cover" />
                    </div>
                    <div>
                      <p className="font-semibold">
                        {selectedInvestmentType.investmentName}
                      </p>
                      <p className="text-sm text-[#c4c4c4]">
                        {selectedInvestmentType.description}
                      </p>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 text-[#c4c4c4] mt-6 gap-4 md:gap-6">
                    <div>
                      <p className="text-xs text-black">Start Date</p>
                      <p className="font-semibold text-black">
                        {selectedInvestmentType.startDate}
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-black">End Date</p>
                      <p className="font-semibold text-black">
                        {selectedInvestmentType.endDate}
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-black">Minimum Investment</p>
                      <p className="font-semibold text-black">
                        &#8358;{selectedInvestmentType.minimumInvestment}
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-black">Maximum Investment</p>
                      <p className="font-semibold text-black">
                        &#8358;{selectedInvestmentType.maximumInvestment}
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-black">ROI</p>
                      <p className="font-semibold text-black">
                        {selectedInvestmentType.roi} %
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-black">Bonus ROI</p>
                      <p className="font-semibold text-black">
                        {selectedInvestmentType.bonus} %
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-black">Current investors</p>
                      <p className="font-semibold text-black">
                        {selectedInvestmentType.noOfPeople}
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-black">ROI Payout</p>
                      <p className="font-semibold text-black">
                        {selectedInvestmentType.payOut === "0" ? 'Payed' : 'Yet to be paid'}
                      </p>
                    </div>
                  </div>

                  <p className={`text-xs mt-6 mb-4 font-semibold w-fit p-2 rounded ${selectedInvestmentType.status === "Active"
                    ? "bg-primary/10"
                    : "text-black"}`}>
                    Investment Status:{" "}
                    <span
                      className={`font-semibold ${selectedInvestmentType.status === "Active"
                        ? "text-primary/50"
                        : "text-red-500"
                        }`}
                    >
                      {selectedInvestmentType.status}
                    </span>
                  </p>

                  <div className={`grid grid-cols-1 md:grid-cols-${selectedInvestmentType.rollOver === "1" ? '3 gap-2' : '2 gap-4'}`}>
                    <Buttons
                      btnText={`${selectedInvestmentType.status === "Active" ? 'Stop' : 'Start'}  Investment`}
                      btnType={selectedInvestmentType.status === "Active" ? 'delete' : 'primary'}
                      type={""}
                      onClick={() =>
                        changeInvestmentStatus(selectedInvestmentType.id)
                      }
                    />
                    {selectedInvestmentType.rollOver === "1" && (
                      <Buttons
                        btnText={"Update Investment"}
                        btnType={"primary"}
                        type={""}
                      />)}
                    {selectedInvestmentType.rollOver === "1" && (
                      <Buttons
                        btnText={"Rollover Investment"}
                        btnType={"secondary"}
                        onClick={() => setRolloverInvestmentModal(true)}
                        type={""}
                      />
                    )}
                  </div>
                </Modal>

                <Modal isVisible={rolloverInvestmentModal}
                  onClose={() => setRolloverInvestmentModal(false)}>
                  <p className="text-primary font-medium">
                    Complete the form to rollover  {selectedInvestmentType.investmentName} cycle
                  </p>

                  <div>
                    <form className="grid grid-cols-1 gap-4 mt-6" onSubmit={rollOverInvestmentForm.handleSubmit}>
                      <InputWithLabel
                        labelName={"Cycle name"}
                        inputName={"investmentName"}
                        inputType={"text"}
                        inputOnBlur={rollOverInvestmentForm.handleBlur}
                        inputOnChange={rollOverInvestmentForm.handleChange}
                        inputValue={rollOverInvestmentForm.values.investmentName}
                        inputError={
                          rollOverInvestmentForm.touched.investmentName &&
                          rollOverInvestmentForm.errors.investmentName
                            ? rollOverInvestmentForm.errors.investmentName
                            : null
                        }
                      />

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-start">
                        <CurrencyInput
                          labelName={"Minimum investment"}
                          inputName={"minimumInvestment"}
                          inputType={"text"}
                          inputOnBlur={rollOverInvestmentForm.handleBlur}
                          inputOnChange={rollOverInvestmentForm.handleChange}
                          inputValue={rollOverInvestmentForm.values.minimumInvestment}
                          inputError={
                            rollOverInvestmentForm.touched.minimumInvestment &&
                              rollOverInvestmentForm.errors.minimumInvestment
                              ? rollOverInvestmentForm.errors.minimumInvestment
                              : null
                          }
                        />
                        <CurrencyInput
                          labelName={"Maximum investment"}
                          inputName={"maximumInvestment"}
                          inputType={"text"}
                          inputOnBlur={rollOverInvestmentForm.handleBlur}
                          inputOnChange={rollOverInvestmentForm.handleChange}
                          inputValue={rollOverInvestmentForm.values.maximumInvestment}
                          inputError={
                            rollOverInvestmentForm.touched.maximumInvestment &&
                              rollOverInvestmentForm.errors.maximumInvestment
                              ? rollOverInvestmentForm.errors.maximumInvestment
                              : null
                          }
                        />
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-start">
                        <CurrencyInput
                          labelName={"Total amount investment"}
                          inputName={"totalInvestment"}
                          inputType={"text"}
                          inputOnBlur={rollOverInvestmentForm.handleBlur}
                          inputOnChange={rollOverInvestmentForm.handleChange}
                          inputValue={rollOverInvestmentForm.values.totalInvestment}
                          inputError={
                            rollOverInvestmentForm.touched.totalInvestment &&
                              rollOverInvestmentForm.errors.totalInvestment
                              ? rollOverInvestmentForm.errors.totalInvestment
                              : null
                          }
                        />
                        <InputWithLabel
                          labelName={"ROI (%)"}
                          inputName={"roiPercentage"}
                          inputType={"number"}
                          inputOnBlur={rollOverInvestmentForm.handleBlur}
                          inputOnChange={rollOverInvestmentForm.handleChange}
                          inputValue={rollOverInvestmentForm.values.roiPercentage}
                          inputError={
                            rollOverInvestmentForm.touched.roiPercentage &&
                              rollOverInvestmentForm.errors.roiPercentage
                              ? rollOverInvestmentForm.errors.roiPercentage
                              : null
                          }
                        />
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-start">
                        <InputWithLabel
                          labelName={"Start date"}
                          inputName={"startDate"}
                          inputType={"date"}
                          inputOnBlur={rollOverInvestmentForm.handleBlur}
                          inputOnChange={rollOverInvestmentForm.handleChange}
                          inputValue={rollOverInvestmentForm.values.startDate}
                          inputError={
                            rollOverInvestmentForm.touched.startDate &&
                              rollOverInvestmentForm.errors.startDate
                              ? rollOverInvestmentForm.errors.startDate
                              : null
                          }
                        />
                        <InputWithLabel
                          labelName={"Number of days"}
                          inputName={"noOfDays"}
                          inputType={"number"}
                          inputOnBlur={rollOverInvestmentForm.handleBlur}
                          inputOnChange={rollOverInvestmentForm.handleChange}
                          inputValue={rollOverInvestmentForm.values.noOfDays}
                          inputError={
                            rollOverInvestmentForm.touched.noOfDays &&
                              rollOverInvestmentForm.errors.noOfDays
                              ? rollOverInvestmentForm.errors.noOfDays
                              : null
                          }
                        />
                      </div>

                      <div className="grid mb-10">
                        <span className="text-sm font-medium">
                          Investment description:<code>*</code>
                        </span>
                        <textarea
                          rows="5"
                          cols="5"
                          name="description"
                          className="px-3 py-4 bg-[#f8f8f8] border focus:border-primary focus:border-1 transition-all focus:outline-none rounded"
                          onBlur={rollOverInvestmentForm.handleBlur}
                          onChange={rollOverInvestmentForm.handleChange}
                          value={rollOverInvestmentForm.values.description}
                        ></textarea>
                        <code className="text-red-500 text-xs">
                          {rollOverInvestmentForm.touched.description &&
                            rollOverInvestmentForm.errors.description
                            ? rollOverInvestmentForm.errors.description
                            : null}{" "}
                        </code>
                      </div>

                      <Buttons btnText={"Continue"} btnType={"primary"} type={"submit"} />
                    </form>
                  </div>
                </Modal>
              </div>
            )}
          </div>
        ) : (
          <div className="grid gap-4 my-16 ">
            <div className="w-full grid justify-center">
              <img src={comingSoon} alt="" />
            </div>
            <p className="text-center font-semibold text-primary">
              No investments available under "{investmentName}" Investment
            </p>
            {/* <p className="text-center">Click the Add Investment Button to create investment. </p> */}
          </div>
        )}
      </div>

      {/* Investor */}
      <div id="investorsTab" className={tabVisibility.investorsTab ? "" : "hidden"}>
        {investorList.length > 0 ? (
          <div className="bg-white rounded-lg">
            <div className="grid grid-cols-1 md:grid-cols-3 justify-between items-center">
              <div className="flex items-center gap-3 mb-2 col-span-2">
                <span className="text-lg text-primary font-semibold">
                  All {investmentName} Investors
                </span>
              </div>
              <div className="flex items-center justify-center bg-[#f8f8f8] border rounded w-full col-span-1">
                <div className="px-3">
                  <img src={searchIcon} alt="" />
                </div>
                <input
                  type="search"
                  id="searchInput"
                  onInput={SearchTable}
                  className="w-full p-2 bg-[#f8f8f8] text-sm active:outline-none focus:outline-none placeholder:text-xs"
                  placeholder="Search Investors..."
                />
              </div>
            </div>

            <div className="mt-4 flex items-center gap-2">
              <label for="statusFilter" className="block text-sm font-medium">
                Showing :
              </label>
              <select
                id="statusFilter"
                onChange={() => filterTable(6)}
                className="text-sm focus:outline-none focus:border-none "
              >
                <option value="All">All Investors</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>

            <div className="overflow-x-scroll mt-10">
              <table
                className="min-w-full rounded-md overflow-hidden text-sm"
                id="dataTable"
              >
                <thead className="bg-primary/10 text-primary">
                  <tr>
                    <th className="pl-5 pr-3">S/N</th>
                    <th className="px-6 py-4 text-start">
                      <p className="truncate w-[250px]">Investor name</p>
                    </th>
                    <th className="px-3 py-4 text-start">
                      <p className="truncate w-[200px]">Investment</p>
                    </th>
                    <th className="px-3 py-4 text-start">
                      <p className="truncate w-[150px]">Amount invested</p>
                    </th>
                    <th className="px-3 py-4 text-start">
                      <p className="truncate w-[150px]">Date invested</p>
                    </th>
                    <th className="px-3 py-4 text-start">
                      <p className="truncate w-[150px]">Status</p>
                    </th>
                    <th className="px-3 py-4 text-start">
                      <p className="truncate w-[50px]">ROI</p>
                    </th>
                    <th className="px-3 py-4 text-start">
                      <p className="truncate w-[150px]">Investment end date</p>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {investorList.map((val, key) => {
                    return (
                      <tr className="odd:bg-[#F9F9F9] border-t-8 border-t-white" key={key} >
                        <td className="pl-5 pr-3">{key + 1}</td>
                        <td className="px-6 py-4">
                          <p className="truncate w-[250px]">
                            {val.userData?.firstName
                              ? val.userData.firstName
                              : ""}{" "}
                            {val.userData?.lastname
                              ? val.userData.lastname
                              : ""}
                          </p>
                        </td>
                        <td className="px-3 py-4">
                          <p className="truncate w-[200px]">
                            {val.investmentName}
                          </p>
                        </td>
                        <td className="px-3 py-4">
                          <p className="truncate w-[150px]">
                            &#8358;{val.amount}
                          </p>
                        </td>
                        <td className="px-3 py-4">
                          <p className="truncate w-[150px]">{val.dateBooked}</p>
                        </td>
                        <td className="px-3 py-4">
                          <p
                            className={`truncate w-[150px] ${val.status === "Active"
                              ? "text-primary"
                              : val.status === "Pending"
                                ? "text-yellow-500"
                                : "text-red-500"
                              } font-medium`}
                          >
                            {val.status}
                          </p>
                        </td>
                        <td className="px-3 py-4">{val.roi}%</td>
                        <td className="px-3 py-4">
                          <p className="truncate w-[150px]">
                            {val.investmentEndDate}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="grid gap-4 my-16 ">
            <div className="w-full grid justify-center">
              <img src={comingSoon} alt="" />
            </div>
            <p className="text-center font-semibold text-primary">
              No user has booked for "{investmentName}" investment
            </p>
          </div>
        )}
      </div>

      {/* Delete Investment Modal */}
      <Modal
        isVisible={deleteInvestmentModal}
        onClose={() => setDeleteInvestmentModal(false)}
      >
        <p className="text-red-500 font-medium">
          Are you sure you want to delete "{investmentName}" investment
        </p>
        <p className="mt-2 text-sm text-black/50">
          This action can't be undone. You'll delete "{investmentName}" and
          every associated investment detail
        </p>

        <div className="grid gap-4 text-sm mt-10">
          <button
            className="p-4 rounded-md bg-red-500 text-white truncate"
            onClick={handleDeleteInvestment}
          >
            Yes, delete "{investmentName}"
          </button>
          <button
            className="p-4 rounded-md text-red-500 border-[1.5px] border-red-400"
            onClick={() => setDeleteInvestmentModal(false)}
          >
            Cancel
          </button>
        </div>
      </Modal>

      {/* Create Investment */}
      <Modal
        isVisible={createInvestmentModal}
        onClose={() => setCreateInvestmentModal(false)}
      >
        <p className="text-primary font-medium">
          Complete the form to create a new "{investmentName}" cycle
        </p>

        <div>
          <form className="grid grid-cols-1 gap-4 mt-6" onSubmit={createInvestmentForm.handleSubmit}>
            <InputWithLabel
              labelName={"Cycle name"}
              inputName={"investmentName"}
              inputType={"text"}
              inputOnBlur={createInvestmentForm.handleBlur}
              inputOnChange={createInvestmentForm.handleChange}
              inputValue={createInvestmentForm.values.investmentName}
              inputError={
                createInvestmentForm.touched.investmentName &&
                  createInvestmentForm.errors.investmentName
                  ? createInvestmentForm.errors.investmentName
                  : null
              }
            />

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-start">
              <CurrencyInput
                labelName={"Minimum investment"}
                inputName={"minimumInvestment"}
                inputType={"text"}
                inputOnBlur={createInvestmentForm.handleBlur}
                inputOnChange={createInvestmentForm.handleChange}
                inputValue={createInvestmentForm.values.minimumInvestment}
                inputError={
                  createInvestmentForm.touched.minimumInvestment &&
                    createInvestmentForm.errors.minimumInvestment
                    ? createInvestmentForm.errors.minimumInvestment
                    : null
                }
              />
              <CurrencyInput
                labelName={"Maximum investment"}
                inputName={"maximumInvestment"}
                inputType={"text"}
                inputOnBlur={createInvestmentForm.handleBlur}
                inputOnChange={createInvestmentForm.handleChange}
                inputValue={createInvestmentForm.values.maximumInvestment}
                inputError={
                  createInvestmentForm.touched.maximumInvestment &&
                    createInvestmentForm.errors.maximumInvestment
                    ? createInvestmentForm.errors.maximumInvestment
                    : null
                }
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-start">
              <CurrencyInput
                labelName={"Total amount investment"}
                inputName={"totalInvestment"}
                inputType={"text"}
                inputOnBlur={createInvestmentForm.handleBlur}
                inputOnChange={createInvestmentForm.handleChange}
                inputValue={createInvestmentForm.values.totalInvestment}
                inputError={
                  createInvestmentForm.touched.totalInvestment &&
                    createInvestmentForm.errors.totalInvestment
                    ? createInvestmentForm.errors.totalInvestment
                    : null
                }
              />
              <InputWithLabel
                labelName={"ROI (%)"}
                inputName={"roiPercentage"}
                inputType={"number"}
                inputOnBlur={createInvestmentForm.handleBlur}
                inputOnChange={createInvestmentForm.handleChange}
                inputValue={createInvestmentForm.values.roiPercentage}
                inputError={
                  createInvestmentForm.touched.roiPercentage &&
                    createInvestmentForm.errors.roiPercentage
                    ? createInvestmentForm.errors.roiPercentage
                    : null
                }
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-start">
              <InputWithLabel
                labelName={"Start date"}
                inputName={"startDate"}
                inputType={"date"}
                inputOnBlur={createInvestmentForm.handleBlur}
                inputOnChange={createInvestmentForm.handleChange}
                inputValue={createInvestmentForm.values.startDate}
                inputError={
                  createInvestmentForm.touched.startDate &&
                    createInvestmentForm.errors.startDate
                    ? createInvestmentForm.errors.startDate
                    : null
                }
              />
              <InputWithLabel
                labelName={"Number of days"}
                inputName={"noOfDays"}
                inputType={"number"}
                inputOnBlur={createInvestmentForm.handleBlur}
                inputOnChange={createInvestmentForm.handleChange}
                inputValue={createInvestmentForm.values.noOfDays}
                inputError={
                  createInvestmentForm.touched.noOfDays &&
                    createInvestmentForm.errors.noOfDays
                    ? createInvestmentForm.errors.noOfDays
                    : null
                }
              />
            </div>

            <div className="grid grid-cols-1">
              <span className="text-sm font-medium pb-1">Investment image:</span>
              <input
                type="file"
                className="p-3 bg-[#f8f8f8] border text-sm rounded"
                ref={fileInputRef}
                id="fileInput"
                onChange={handleInvestmentPictureUpload}
              />
              {selectedFileError && (
                <code className="text-red-500 text-xs">
                  Kindly upload a image for the investment
                </code>
              )}
            </div>

            <div className="grid mb-10">
              <span className="text-sm font-medium">
                Investment description:<code>*</code>
              </span>
              <textarea
                rows="5"
                cols="5"
                name="description"
                className="px-3 py-4 bg-[#f8f8f8] border focus:border-primary focus:border-1 transition-all focus:outline-none rounded"
                onBlur={createInvestmentForm.handleBlur}
                onChange={createInvestmentForm.handleChange}
                value={createInvestmentForm.values.description}
              ></textarea>
              <code className="text-red-500 text-xs">
                {createInvestmentForm.touched.description &&
                  createInvestmentForm.errors.description
                  ? createInvestmentForm.errors.description
                  : null}{" "}
              </code>
            </div>

            <Buttons btnText={"Continue"} btnType={"primary"} type={"submit"} />
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default SingleInvestmentType;