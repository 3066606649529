import { useEffect, useState } from "react";
import { getPeriodOfDay } from "../../../Utils/utils";
import { useSelector } from "react-redux";
import { useDashboardSummary } from "../adminLayout/reusableEffect";
import Spinner from "../../../Components/spinner";
import { Link } from "react-router-dom";

const AdminDashboard = ({ setPageTitle }) => {

    useEffect(() => {
        setPageTitle("Dashboard");
        document.title = "Dashboard | Agro-Allied";
        document.querySelector('meta[name="description"]').content = "Track investments, gain insights, and grow wealth with Agro-Allied dashboard.";
        setTimeOfTheDay(getPeriodOfDay);
    }, [setPageTitle]);
    const [timeOfTheDay, setTimeOfTheDay] = useState("");
    const adminSession = useSelector((state) => state.admin.adminSessionData)
    const dashboardData = useDashboardSummary();
    return (
        <div className="col-span-10">
            <Spinner loading={useSelector((state) => state.admin).loading} />
            <div className="bg-white p-4 md:px-8 rounded-lg">
                <p>Good {timeOfTheDay} <span className="font-semibold">{adminSession.firstName}</span></p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8">
                    <Link to={'customer'} >
                        <div className="border rounded-md hover:bg-primary hover:text-white p-3 md:p-5 transition-all">
                            <p className="text-2xl font-semibold">{dashboardData?.totalUser ? dashboardData.totalUser : '0'}</p>
                            <p className="overflow-hidden text-sm">Active customers.</p>
                        </div>
                    </Link>
                    <Link to={"investment"}>
                        <div className="border rounded-md hover:bg-primary hover:text-white p-3 md:p-5 transition-all">
                            <p className="text-2xl font-semibold">{dashboardData?.totalNoOfInvestment ? dashboardData.totalNoOfInvestment : '0'}</p>
                            <p className="overflow-hidden text-sm">Total investments (count).</p>
                        </div>
                    </Link>
                    <Link to={"requests"}>
                        <div className="border rounded-md hover:bg-primary hover:text-white p-3 md:p-5 transition-all">
                            <p className="text-2xl font-semibold">{dashboardData?.totalNoOfUserInvestment ? dashboardData.totalNoOfUserInvestment : '0'}</p>
                            <p className="overflow-hidden text-sm">Total number of customer investments.</p>
                        </div>
                    </Link>
                    <Link to={"requests"}>
                        <div className="border rounded-md hover:bg-primary hover:text-white p-3 md:p-5 transition-all">
                            <p className="text-2xl font-semibold">{dashboardData?.pendingInvestment ? dashboardData.pendingInvestment : '0'}</p>
                            <p className="overflow-hidden text-sm">Pending requests.</p>
                        </div>
                    </Link>
                    <Link to={"requests"}>
                        <div className="border rounded-md hover:bg-primary hover:text-white p-3 md:p-5 transition-all">
                            <p className="text-2xl font-semibold">{dashboardData?.todayInvestment ? dashboardData.todayInvestment : '0'}</p>
                            <p className="overflow-hidden text-sm">Today's investment.</p>
                        </div>
                    </Link>
                    <Link to={"withdrawals"}>
                        <div className="border rounded-md hover:bg-primary hover:text-white p-3 md:p-5 transition-all">
                            <p className="text-2xl font-semibold">{dashboardData?.totalWithdrawal ? dashboardData.totalWithdrawal : '0'}</p>
                            <p className="overflow-hidden text-sm">Total number of withdrawals.</p>
                        </div>
                    </Link>
                    <Link to={"withdrawals"}>
                        <div className="border rounded-md hover:bg-primary hover:text-white p-3 md:p-5 transition-all">
                          <p className="text-2xl font-semibold">{dashboardData?.todayWithdrawal ? dashboardData.todayWithdrawal : '0'}</p>
                          <p className="overflow-hidden text-sm ">Today's withdrawals.</p>
                        </div>
                    </Link>
                    <Link to={"withdrawals"}>
                        <div className="border rounded-md hover:bg-primary hover:text-white p-3 md:p-5 transition-all">
                            <p className="text-2xl font-semibold">{dashboardData?.pendingWithdrawal ? dashboardData.pendingWithdrawal : '0'}</p>
                            <p className="overflow-hidden text-sm">Total Pending Withdrawal Request.</p>
                        </div>
                    </Link>

                </div>
            </div>
        </div>
    );
}

export default AdminDashboard;