const SelectInput = ({
  labelName,
  selectOptions,
  valueKey,
  labelKey,
  selectError,
  selectValue,
  selectBlur,
  selectName,
  onChange,
}) => {
  return (
    <div className="grid">
      <span className="text-sm font-medium pb-1">{labelName}:</span>
      <select
        type="text"
        value={selectValue}
        onBlur={selectBlur}
        name={selectName}
        onChange={onChange}
        className="p-[13px] bg-[#f8f8f8] border text-sm rounded focus:border-primary focus:border-1 transition-all focus:outline-none"
      >
        <option value="" disabled selected defaultValue>
          Select a {labelName}
        </option>
        {selectOptions.map((option) => (
          <option key={option[valueKey]} value={option[valueKey]}>
            {option[labelKey]}{" "}
          </option>
        ))}
      </select>
      <code className="text-red-500 text-xs">{selectError}</code>
    </div>
  );
};
export default SelectInput;
