const InputWithLabel = ({ labelName, inputType, placeholder, inputValue, inputOnChange,inputOnBlur,inputError, inputName }) => {
    return (
        <div className="grid w-full">
            <div className="grid">
                <span className="text-sm font-medium pb-1">{labelName}<span className="text-red-500">*</span>:</span>
                <input type={inputType} value={inputValue} name={inputName} onBlur={inputOnBlur} onChange={inputOnChange} className="px-3 py-4 bg-[#f8f8f8] border focus:border-primary focus:border-1 transition-all focus:outline-none text-sm rounded" placeholder={placeholder} />
            </div>
            <code className="text-red-500 text-xs mt-[1px]">{inputError}</code>
        </div>
    )
}
export default InputWithLabel;