import { useState } from "react";
import eye from "../assets/icons/passwordEye.svg"

const PasswordInput = ({ labelName, inputValue, placeholder, inputOnChange,inputOnBlur,inputError, inputName }) => {
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    return (
        <div className="grid">
            <span className="text-sm font-medium pb-1">{labelName}:</span>
           <div className="relative">
           <input type={passwordVisibility?'text':'password'} name={inputName} onBlur={inputOnBlur} onChange={inputOnChange} value={inputValue} className="p-3 bg-[#f8f8f8] border text-sm rounded w-full focus:border-primary focus:border-1 transition-all focus:outline-none"  placeholder={placeholder} />
            <button  className="absolute inset-y-0 right-0 flex items-center px-4" type="button" onClick={()=>setPasswordVisibility(!passwordVisibility)}>
              <img src={eye} alt="toggle" className="h-5"/>
            </button>
            
           </div>
            <code className="text-red-500 text-xs mt-[1px]">{inputError}</code>
        </div>
    )
}
export default PasswordInput;