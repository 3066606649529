import {  useDispatch, useSelector } from "react-redux";
import NavigationHeader from "../../../Components/navigationHeader";
import Spinner from "../../../Components/spinner";
import { useEffect, useState } from "react";
import searchIcon from "../../../assets/icons/search.svg";
import { SearchTable } from "../../../Utils/utils";
import Modal from "../../../Components/modals";
import InputWithLabel from "../../../Components/inputWithLabel";
import ReadOnlyInputWithLabel from "../../../Components/readOnlyInput";
import Buttons from "../../../Components/buttons";
import { useParams } from "react-router-dom";
import { useInvestmentCycleInvestors } from "../adminLayout/reusableEffect";
import comingSoon from "../../../assets/icons/comingSoon.svg";
import PasswordInput from "../../../Components/passwordInput";
import { showErrorToastMessage } from "../../../Utils/constant";
import { investmentROIPayout } from "../../../hooks/local/adminReducer";

const CycleInvestors = ({ setPageTitle }) => {
  useEffect(() => {
    setPageTitle("Investments");
    document.title = "Investors | Agro-Allied";
    document.querySelector('meta[name="description"]').content = "Explore investment opportunities, manage your investments, and monitor your portfolio seamlessly with Agro-Allied.";
  }, [setPageTitle]);

  let { investmentId, investmentName, investmentTypeId, roi } = useParams();
  investmentId = atob(investmentId);
  investmentName = atob(investmentName);
  investmentTypeId = atob(investmentTypeId);
  roi = parseFloat(atob(roi));
  const [payoutModal, setPayoutModal] = useState(false);
  const [rolloverModal, setRolloverModal] = useState(false);
  const [bonusRoi, setBonusRoi] = useState("")
  const [totalRoi, setTotalRoi] = useState(0);
  const [adminPin, setAdminPin] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const bonus = parseFloat(bonusRoi) || 0;
    setTotalRoi(roi + bonus);
  }, [roi, bonusRoi])

  const investorList = useInvestmentCycleInvestors(investmentId, investmentTypeId)

  const setPayout = async() =>{
    if(adminPin.length < 0 || adminPin === ""){
      return showErrorToastMessage("Admin Pin is required")
    }
    if(!bonusRoi){
      setBonusRoi(0);
    }
    const payOutData = {investmentId, investmentTypeId, adminPin, roi:totalRoi, bonus:bonusRoi}
    const { payload } = await dispatch(investmentROIPayout(payOutData));
    if(payload.statusCode === "200"){
      setAdminPin("");
      setPayoutModal(false);
    }
  }
  return (
    <div className="col-span-10">
      <Spinner loading={useSelector((state) => state.admin).loading} />
      <div className="md:flex justify-between items-center">
        <NavigationHeader title={`${investmentName} investors`} />
        {
          investorList.length > 0 && (
            <div className="mt-4 md:mt-0 flex gap-4 text-sm md:text-xs overflow-x-scroll whitespace-nowrap">
            <button
              className="bg-primary text-white rounded px-6 py-4"
              onClick={() => setPayoutModal(true)}
            >
              Return Payout
            </button>
            {/* <button
              className="text-primary border-[1.5px] border-primary rounded px-6 py-4 font-medium"
              onClick={() => setRolloverModal(true)}
            >
              Rollover
            </button> */}
          </div>
          )
        }
       
      </div>
      <div className="bg-white rounded-lg mt-6">
        <div className="grid grid-cols-1 md:grid-cols-3">
          <div className="flex items-center justify-center bg-[#f8f8f8] border rounded w-full col-span-1">
            <div className="px-3">
              <img src={searchIcon} alt="" />
            </div>
            <input
              type="search"
              id="searchInput"
              onInput={SearchTable}
              className="w-full p-2 bg-[#f8f8f8] text-sm active:outline-none focus:outline-none placeholder:text-xs"
              placeholder="Search Investors..."
            />
          </div>
        </div>

        <div className="overflow-x-scroll mt-10">
          {
            investorList.length > 0
              ?
              (
                <table
                  className="min-w-full rounded-md overflow-hidden text-sm"
                  id="dataTable"
                >
                  <thead className="bg-primary/10 text-primary">
                    <tr>
                      <th className="pl-5 pr-3">S/N</th>
                      <th className="pl-6 py-4 text-start">
                        <p className="truncate w-[130px]">Investor name</p>
                      </th>
                      <th className="px-2 py-4 text-start">
                        <p className="truncate w-[130px]">Payment Reference</p>
                      </th>

                      <th className="px-3 py-4 text-start">
                        <p className="truncate w-[120px]">Amount invested</p>
                      </th>
                      <th className="px-3 py-4 text-start">
                        <p className="truncate w-[90px]">Date invested</p>
                      </th>
                      <th className="px-3 py-4 text-start">
                        <p className="truncate w-[50px]">Status</p>
                      </th>
                      <th className="px-3 py-4 text-start">
                        <p className="truncate w-[30px]">ROI</p>
                      </th>
                      <th className="px-3 py-4 text-start">
                        <p className="truncate w-[50px]">Cycle end date</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      investorList.map((val, key) => {
                        return (
                          <tr className="odd:bg-[#F9F9F9] border-t-8 border-t-white" key={key}>
                            <td className="pl-5 pr-3">{key + 1}</td>
                            <td className="pl-6 py-4">
                              <p className="w-[130px]">{val.userData.firstName} {val.userData.lastname}</p>
                            </td>
                            <td className="px-2 py-4">
                              <p className="w-[130px]">{val.referenceNumber}</p>
                            </td>

                            <td className="px-3 py-4">
                              <p className="w-[120px]">{val.amount}</p>
                            </td>
                            <td className="px-3 py-4">
                              <p className="w-[80px]">{val.dateBooked}</p>
                            </td>
                            <td className="px-3 py-4">
                              <p>{val.status}</p>
                            </td>
                            <td className="px-3 py-4">{val.roi} %</td>
                            <td className="px-3 py-4">
                              <p className="">{val.investmentEndDate}</p>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              )
              :
              (
                <div className="grid gap-4 my-16">
                  <div className="w-full grid justify-center">
                    <img src={comingSoon} alt="" />
                  </div>
                  <p className="text-center text-lg font-semibold text-primary">No investor available for this circle</p>
                </div>
              )
          }

        </div>

      </div>
      {payoutModal && (
        <Modal isVisible={payoutModal} onClose={() => setPayoutModal(false)}>
          <div className="grid gap-4">
            <p className="text-primary font-semibold">Process return payout</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <ReadOnlyInputWithLabel
                labelName={"Expected ROI (%)"}
                inputType={"text"}
                inputName={"expextedROI"}
                inputValue={`${roi}%`}
              />
              <InputWithLabel
                labelName={"Bonus ROI (%)"}
                inputType={"number"}
                inputName={"bonusROI"}
                inputValue={bonusRoi}
                inputOnChange={(e) => setBonusRoi(e.target.value)}
              />
            </div>
            <ReadOnlyInputWithLabel
              labelName={"Total ROI earned in this cycle(%)"}
              inputType={"text"}
              inputName={"totalRoi"}
              inputValue={totalRoi}
            />
            <PasswordInput labelName={'Admin Transaction Pin (4 digit Pin)'}
                                inputName={'transactionPin'}
                                inputOnChange={(e)=>setAdminPin(e.target.value)}
                                inputValue={adminPin}
                               />
            <div className="mt-6">
              <Buttons btnText={"Payout!"} btnType={"primary"} onClick={setPayout}/>
            </div>
          </div>
        </Modal>
      )}

      {rolloverModal && (
        <Modal isVisible={rolloverModal} onClose={() => setRolloverModal(false)}>
          <div className="grid gap-4">
            <p className="text-primary font-semibold">Rollover (Investment cycle history)</p>
            <div className="overflow-x-scroll mt-5">
              <table className="min-w-full rounded-md overflow-hidden text-sm" id="dataTable">
                <thead className="bg-primary/10 text-primary">
                  <tr>
                    <th className="pl-5 pr-3">S/N</th>
                    <th className="px-3 py-4 text-start">
                      <p className="truncate max-w-[200px]">Cycle</p>
                    </th>
                    <th className="px-3 py-4 text-start">
                      <p className="truncate">Total amount invested</p>
                    </th>
                    <th className="px-3 py-4 text-start">
                      <p className="truncate">Start date</p>
                    </th>
                    <th className="px-3 py-4 text-start">
                      <p className="truncate">End date</p>
                    </th>
                    <th className="px-3 py-4 text-start">
                      <p className="truncate">Expected ROI (%)</p>
                    </th>
                    <th className="px-3 py-4 text-start">
                      <p className="truncate">Actual ROI (%)</p>
                    </th>
                  </tr>
                </thead>

                <tbody>

                  <tr className="odd:bg-[#F9F9F9] border-t-8 border-t-white">
                    <td className="pl-5 pr-3">1</td>

                    <td className="px-3 py-4">
                      <p className="truncate max-w-[200px]">
                        December 2024 fish farming
                      </p>
                    </td>
                    <td className="px-3 py-4">
                      <p className="truncate ">
                        100,000,000
                      </p>
                    </td>
                    <td className="px-3 py-4">
                      <p className="truncate">Dec 99, 1999</p>
                    </td>
                    <td className="px-3 py-4">
                      <p className="truncate">
                        Feb 44, 1444
                      </p>
                    </td>
                    <td className="px-3 py-4">10%</td>
                    <td className="px-3 py-4">
                      <p className="truncate">
                        12%
                      </p>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default CycleInvestors;