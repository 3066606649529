import { useCurrencyDigit } from "../Utils/utils";

const CurrencyInput = ({ labelName, placeholder, inputValue, inputOnChange,inputOnBlur,inputError, inputName }) => {
    useCurrencyDigit(inputOnChange);
    return (
        <div className="grid w-full">
            <div className="grid">
              <span className="text-sm font-medium pb-1">{labelName}:</span>
              <input type='text' value={inputValue} name={inputName} onBlur={inputOnBlur} onChange={inputOnChange} className="px-3 py-4 bg-[#f8f8f8] border focus:border-primary focus:border-1 transition-all focus:outline-none text-sm rounded currencyDigit" placeholder={placeholder} />
            </div>
            <code className="text-red-500 text-xs mt-1">{inputError}</code>
        </div>
    )
}
 
export default CurrencyInput;