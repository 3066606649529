import { useEffect, useState } from "react";
import NavigationHeader from "../../../Components/navigationHeader";
import InputWithLabel from "../../../Components/inputWithLabel";
import Buttons from "../../../Components/buttons";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import Spinner from "../../../Components/spinner";
import { useUserBalanceSummary, useUserInvestmentList } from "../userLayout/reusableEffects";
import investmentIcon from "../../../assets/icons/investment.svg";
import comingSoon from "../../../assets/icons/comingSoon.svg";
import { Link } from "react-router-dom";
const UserProfile = ({ setPageTitle }) => {
    useEffect(() => {
        setPageTitle("Profile");
        document.title = "Profile | Agro-Allied";
        document.querySelector('meta[name="description"]').content = "Track investments, gain insights, and grow wealth with Agro-Allied dashboard.";
    }, [setPageTitle]);
    const userBalanceSummary = useUserBalanceSummary();
    const investmentBalance = userBalanceSummary?.investmentBalance? userBalanceSummary.investmentBalance : '0.00'; 
    const roiBalance = userBalanceSummary?.roiBalance? userBalanceSummary.roiBalance : '0.00'; 
    const amountWithdrawal = userBalanceSummary?.amountWithdraw? userBalanceSummary.amountWithdraw : '0.00';  
    const [tabVisibility, setTabVisibility] = useState({ profileTab: true, investmentTab: false });
    const userData = useSelector((state)=>state.user.userSummaryData);
    const investmentData = useUserInvestmentList();

    const showTab = (tabId) => {
        setTabVisibility(() => ({
            profileTab: tabId === 'profileTab',
            investmentTab: tabId === 'investmentTab',
            accountTab: tabId === 'accountTab',
        }))
    }

    const updateUserProfile = useFormik({
        initialValues : {
            ...userData.userDetails
        },
        validationSchema : {

        }
    })

    return (
        <div className="col-span-10 md:mx-4">
            <Spinner loading={useSelector((state)=>state.user).loading}/>
            <NavigationHeader title={'Profile Details'} />
            <div className="mt-10 flex gap-4 mb-4 font-medium">
                <span className={`py-3 px-5 text-xs rounded-md ${tabVisibility.profileTab ? 'bg-primary text-white cursor-default' : 'border border-black/30 text-black/40 cursor-pointer'}`} id="personalDetails" onClick={() => showTab('profileTab')}>Personal Details</span>
                <span className={`py-3 px-5 text-xs rounded-md ${tabVisibility.investmentTab ? 'bg-primary text-white cursor-default' : 'border border-black/30 text-black/40 cursor-pointer'}`} id="investmentDetails" onClick={() => showTab('investmentTab')} >Investment Details</span>
            </div>

            <div id="profileTab" className={tabVisibility.profileTab ? '' : 'hidden'}>
                <div className="flex items-center my-10 gap-4">
                    <img src={userData.userDetails.passport} alt="" className="rounded-full w-32 aspect-square border-[3px] border-primary/50" />
                    <div className="col-span-8 md:col-span-10 truncate">
                        <p className="font-semibold truncate">{userData.userDetails.firstname}  {userData.userDetails.lastname}</p>
                        <p className="text-sm font-medium text-black/40 truncate">{userData.userDetails.userEmailAddress}</p>
                    </div>
                </div>

                <form className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-4">
                    <InputWithLabel labelName={'Legal first name'}
                                    inputName={'firstname'}
                                    inputValue={updateUserProfile.values.firstname}
                                     inputType={'text'} />
                    <InputWithLabel labelName={'Legal middle name'}
                                    inputType={'text'}
                                    inputName={'middleName'}
                                    inputValue={updateUserProfile.values.middleName} />
                    <InputWithLabel labelName={'Legal last name'}
                                    inputType={'text'}
                                    inputName={'lastname'}
                                    inputValue={updateUserProfile.values.lastname} />
                    <InputWithLabel labelName={'Home address'}
                                    inputType={'text'}
                                    inputName={'homeAddress'}
                                    inputValue={updateUserProfile.values.homeAddress} />
                    <InputWithLabel labelName={'Gender'}
                                    inputType={'text'}
                                    inputName={'gender'}
                                    inputValue={updateUserProfile.values.gender} />
                    <InputWithLabel labelName={'Next of kin'}
                                    inputType={'text'}
                                    inputName={'nextOfKinName'}
                                    inputValue={updateUserProfile.values.nextOfKinName} />
                    <InputWithLabel labelName={'Bank verification Number (BVN)'}
                                    inputType={'number'}
                                    inputName={'bvn'}
                                    inputValue={updateUserProfile.values.bvn} />
                    <InputWithLabel labelName={'Next of kin Phone number'}
                                    inputType={'text'}
                                    inputName={'nextOfKinPhoneNumber'}
                                    inputValue={updateUserProfile.values.nextOfKinPhoneNumber} />
                    <InputWithLabel labelName={'Next of kin address'}
                                    inputType={'text'}
                                    inputName={'nextOfKinAddress'}
                                    inputValue={updateUserProfile.values.nextOfKinAddress} />
                    <div className="items-center pt-5"> 
                     <Buttons btnText={'Update Account'} btnType={'primary'} type={'button'} />
                    </div>
                </form>
            </div>

            <div id="investmentTab" className={tabVisibility.investmentTab ? '' : 'hidden'}>
            <div className="grid grid-cols-1 md:grid-cols-3 border rounded-md p-2">
                  <div className="grid p-4 gap-4">
                      <p className="font-medium text-black/60 text-sm">Investment Balance</p>
                      <p className="text-lg font-semibold">&#8358;{investmentBalance}</p>
                  </div>

                  <div className="grid p-4 border-x-0 border-y md:border-x md:border-y-0 gap-4">
                      <p className="font-medium text-black/60 text-sm">ROI Balance</p>
                      <p className="text-lg font-semibold">&#8358;{roiBalance}</p>
                  </div>

                  <div className="grid p-4 gap-4">
                      <p className="font-medium text-black/60 text-sm">Total Withdrawal</p>
                      <p className="text-lg font-semibold">&#8358;{amountWithdrawal}</p>
                  </div>
              </div>
              <p className="font-semibold py-4">Investments</p>
              <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
               {
                investmentData.length > 0
                ?
               investmentData.map((val,key)=>{
                    return(
                        <div className="bg-[#F5F5F5] py-4 px-4 rounded-lg grid md:flex gap-4 items-center" key={key}>
                        <img src={investmentIcon} alt=""/>
                        <div className="grid grid-cols-1 w-full items-center">
                          <div className="flex justify-between">
                            <div>
                              <p className="truncate overflow-hidden text-sm">{val.investmentName}</p>
                              <p className="truncate overflow-hidden text-xs pt-2"><span className="font-semibold pr-2">Amount:</span><span>&#8358;{val.amount}</span></p>
                            </div>
                            <div>
                              <p className="text-xs"><span className="font-semibold pr-2">Status:</span><span>{val.status}</span></p>
                              <p className="text-xs pt-2"><span className="font-semibold pr-2">ROI:</span><span>{val.roi} %</span></p>
                            </div>
                          </div>
                          <div className="flex justify-between pt-1">
                              <p className="text-xs"><span className="font-semibold pr-2">Investment End Date:</span><span>{val.investmentEndDate}</span></p>                          
                              <p className="text-xs"><span className="font-semibold pr-2">Booked Date:</span><span>{val.insertedDt}</span></p>
                          </div>
                        </div>   
                      </div>
                    )
               })
               :
               (
                <div className="grid gap-4 my-16">
                    <div className="w-full grid justify-center">
                        <img src={comingSoon} alt="" />
                    </div>
                    <p className="text-center text-lg font-semibold">No Investment Booked yet</p>
                    <Link to={"/investment"} className="text-center underline text-primary font-semibold text-sm">Book an investment</Link>
                </div>
               )
               }
             </div>
            </div>

        </div>
    );
}

export default UserProfile;