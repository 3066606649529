import { useEffect, useState } from "react";
import NavigationHeader from "../../../Components/navigationHeader";
import Spinner from "../../../Components/spinner";
import { useDispatch, useSelector } from "react-redux";
import passwordResetImage from "../../../assets/images/passwordReset.png";
import pinResetImage from "../../../assets/images/pinReset.png";
import bankAccountResetImage from "../../../assets/images/bankAccount.png";
import Modal from "../../../Components/modals";
import arrow from "../../../assets/icons/arrow3.svg"
import PasswordInput from "../../../Components/passwordInput";
import Buttons from "../../../Components/buttons";
import InputWithLabel from "../../../Components/inputWithLabel";
import { useFormik } from "formik";
import * as Yup from "yup";
import { bankAccount, updateAdminPassword, updateAdminTransactionPin, updateDepositBankAccount } from "../../../hooks/local/adminReducer";

const Settings = ({ setPageTitle }) => {
    useEffect(() => {
        setPageTitle("Settings");
        document.title = "Settings | Agro-Allied";
        document.querySelector('meta[name="description"]').content = "Your access point to intelligent administration and tailored wealth expansion.";
    }, [setPageTitle]);
    const dispatch = useDispatch();
    const bankAccountData = useSelector((state)=>state.admin.bankAccountData);
    const emailAddress = useSelector((state)=>state.admin.adminSessionData).emailAddress;
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [changePinModal, setChangePinModal] = useState(false);
    const [changeBankAccountModal, setChangeBankAccountModal] = useState(false);

    const changePasswordForm = useFormik({
        initialValues: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
     validationSchema: Yup.object({
        oldPassword: Yup.string().required("Kindly enter the former password"),
        newPassword: Yup.string().required("New password cannot be empty"),
        confirmPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
     }),
        onSubmit: async (values) => {
            const {oldPassword, newPassword, confirmPassword} = values;
            let passwordData = {emailAddress, oldPassword, newPassword, confirmPassword};
            const {payload} = await dispatch(updateAdminPassword(passwordData));
            if(payload.statusCode === "200"){
                setChangePasswordModal(false);
            }
        },
    })
    const changeTransactionPinForm = useFormik({
        initialValues: {
            password: "",
            transactionPin: ""
        },
     validationSchema: Yup.object({
        password: Yup.string().required("Admin Password cannot be empty"),
        transactionPin: Yup.string().required("Transaction Pin cannot be empty").typeError('Transaction Pin can only be in Number').matches(/^\d{4}$/, 'Transaction Pin must be exactly 4 digits'),
     }),
        onSubmit: async (values) => {
            const {password, transactionPin} = values;
            let transactionPinData = {emailAddress, password, transactionPin};
            const {payload} = await dispatch(updateAdminTransactionPin(transactionPinData));
            if(payload.statusCode === "200"){
                setChangePinModal(false);
            }
        },
    })
    const depositBankAccountForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            accountName: bankAccountData[0].accountName,
            accountNumber: bankAccountData[0].accountNumber,
            bankName: bankAccountData[0].bankName,
            password:""
        },
     validationSchema: Yup.object({
        accountName: Yup.string().required("Account Name cannot be empty"),
        accountNumber: Yup.number().required("Account Number cannot be empty").typeError('Account Number can only be in Number'),
        bankName: Yup.string().required("Bank Name cannot be empty"),
        password: Yup.string().required("Kindly enter Administrative Password"),
     }),
        onSubmit: async (values, {resetForm}) => {
            const {accountName, accountNumber, bankName, password} = values;
            let bankData = {emailAddress, accountName, accountNumber, bankName, password};
            const {payload} = await dispatch(updateDepositBankAccount(bankData));
            if(payload.statusCode === "200"){
                await dispatch(bankAccount());
                resetForm();
                setChangeBankAccountModal(false);
            }
        },
    })

    return (
        <div className="col-span-10">
            <NavigationHeader title={'Admin Settings'} />
            <Spinner loading={useSelector((state) => state.admin).loading} />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-8">

            <div className="grid gap-4 hover:bg-primary/5 p-4 md:p-8 border hover:border-primary transition-all rounded-md font-medium text-sm cursor-pointer" onClick={()=>setChangePasswordModal(true)}>
                <div className="">
                  <img src={passwordResetImage} className=" h-8" alt="" />
                </div>
                <div className="flex justify-between items-center ">
                    <div>Change Password.</div>
                    <div><img src={arrow} alt="" className="h-3"/></div>
                </div>
               </div>

               <div className="grid gap-4 hover:bg-primary/5 p-4 md:p-8 border hover:border-primary transition-all rounded-md font-medium text-sm cursor-pointer" onClick={()=>setChangePinModal(true)}>
                <div className="">
                  <img src={pinResetImage} className=" h-8" alt="" />
                </div>
                <div className="flex justify-between items-center ">
                    <div>Change Transaction PIN.</div>
                    <div><img src={arrow} alt="" className="h-3"/></div>
                </div>
               </div>

               <div className="grid gap-4 hover:bg-primary/5 p-4 md:p-8 border hover:border-primary transition-all rounded-md font-medium text-sm cursor-pointer" onClick={()=>setChangeBankAccountModal(true)}>
                <div className="">
                  <img src={bankAccountResetImage} className=" h-8" alt="" />
                </div>
                <div className="flex justify-between items-center ">
                    <div>Update Deposit Bank Account.</div>
                    <div><img src={arrow} alt="" className="h-3"/></div>
                </div>
               </div>

            </div>

            <Modal isVisible={changePasswordModal} onClose={() => setChangePasswordModal(false)}>
                <p className="text-primary font-medium">Complete the form to Change Admin Access Password</p>
                <form className="grid gap-6 mt-4" onSubmit={changePasswordForm.handleSubmit}>
                    <PasswordInput labelName={'Old Password'}
                                    inputName={'oldPassword'}
                                    inputOnBlur={changePasswordForm.handleBlur}
                                    inputOnChange={changePasswordForm.handleChange}
                                    inputValue={changePasswordForm.values.oldPassword}
                                    inputError={changePasswordForm.touched.oldPassword && changePasswordForm.errors.oldPassword ? changePasswordForm.errors.oldPassword : null}/>
                    <PasswordInput labelName={'New Password'}
                                    inputName={'newPassword'}
                                    inputOnBlur={changePasswordForm.handleBlur}
                                    inputOnChange={changePasswordForm.handleChange}
                                    inputValue={changePasswordForm.values.newPassword}
                                    inputError={changePasswordForm.touched.newPassword && changePasswordForm.errors.newPassword ? changePasswordForm.errors.newPassword : null}/>
                    <PasswordInput labelName={'Confirm Password'}
                                    inputName={'confirmPassword'}
                                    inputOnBlur={changePasswordForm.handleBlur}
                                    inputOnChange={changePasswordForm.handleChange}
                                    inputValue={changePasswordForm.values.confirmPassword}
                                    inputError={changePasswordForm.touched.confirmPassword && changePasswordForm.errors.confirmPassword ? changePasswordForm.errors.confirmPassword : null}/>
                    <Buttons btnText={'Continue'} btnType={'primary'} type={'submit'} />
                </form>
            </Modal>
            <Modal isVisible={changePinModal} onClose={() => setChangePinModal(false)}>
                <p className="text-primary font-medium">Complete the form to Change Admin Transaction Pin</p>
                <form className="grid gap-6 mt-4" onSubmit={changeTransactionPinForm.handleSubmit}>
                    <PasswordInput labelName={'Admin Password'}  
                                    inputName={'password'}
                                    inputOnBlur={changeTransactionPinForm.handleBlur}
                                    inputOnChange={changeTransactionPinForm.handleChange}
                                    inputValue={changeTransactionPinForm.values.password}
                                    inputError={changeTransactionPinForm.touched.password && changeTransactionPinForm.errors.password ? changeTransactionPinForm.errors.password : null}/>
                    <PasswordInput labelName={'New Transaction Pin (4 digit Pin)'}
                                inputName={'transactionPin'}
                                inputOnBlur={changeTransactionPinForm.handleBlur}
                                inputOnChange={changeTransactionPinForm.handleChange}
                                inputValue={changeTransactionPinForm.values.transactionPin}
                                inputError={changeTransactionPinForm.touched.transactionPin && changeTransactionPinForm.errors.transactionPin ? changeTransactionPinForm.errors.transactionPin : null}/>
                    <Buttons btnText={'Continue'} btnType={'primary'} type={'submit'} />
                </form>
            </Modal>
            <Modal isVisible={changeBankAccountModal} onClose={() => setChangeBankAccountModal(false)}>
                <p className="text-primary font-medium">Complete the form to Change Deposit Bank Details</p>
               <form className="grid gap-6 mt-4" onSubmit={depositBankAccountForm.handleSubmit}>
               <InputWithLabel labelName={'Account Name'}
                               inputType={'text'} 
                               inputName={'accountName'}
                               inputOnBlur={depositBankAccountForm.handleBlur}
                               inputOnChange={depositBankAccountForm.handleChange}
                               inputValue={depositBankAccountForm.values.accountName}
                               inputError={depositBankAccountForm.touched.accountName && depositBankAccountForm.errors.accountName ? depositBankAccountForm.errors.accountName : null}/>
                <InputWithLabel labelName={'Account Number'}
                                inputType={'text'}
                                inputName={'accountNumber'}
                                inputOnBlur={depositBankAccountForm.handleBlur}
                                inputOnChange={depositBankAccountForm.handleChange}
                                inputValue={depositBankAccountForm.values.accountNumber}
                                inputError={depositBankAccountForm.touched.accountNumber && depositBankAccountForm.errors.accountNumber ? depositBankAccountForm.errors.accountNumber : null}/>
                <InputWithLabel labelName={'Bank Name'}
                                inputType={'text'}
                                inputName={'bankName'}
                                inputOnBlur={depositBankAccountForm.handleBlur}
                                inputOnChange={depositBankAccountForm.handleChange}
                                inputValue={depositBankAccountForm.values.bankName}
                                inputError={depositBankAccountForm.touched.bankName && depositBankAccountForm.errors.bankName ? depositBankAccountForm.errors.bankName : null}/>
                <PasswordInput labelName={'Admin Password'}
                               inputName={'password'}
                               inputOnBlur={depositBankAccountForm.handleBlur}
                               inputOnChange={depositBankAccountForm.handleChange}
                               inputValue={depositBankAccountForm.values.password}
                               inputError={depositBankAccountForm.touched.password && depositBankAccountForm.errors.password ? depositBankAccountForm.errors.password : null}/>
                <Buttons btnText={'Continue'} btnType={'primary'} type={'submit'} />
               </form>
            </Modal>

        </div>
    );
}

export default Settings;