import ardvestLogo from '../../../assets/icons/logo.svg';
import notificationLogo from '../../../assets/icons/notification.svg';
import menuIcon from '../../../assets/icons/menu.svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
const AdminTopBar = ({ toggleSideBar, topBarTitle }) => {
    const adminSession= useSelector((state)=>state.admin.adminSessionData)
    const firstName = adminSession.firstName;
    const lastname = adminSession.lastName;

    return (
        <div>
            <div className="border-b h-[10vh] md:h-[12vh] grid grid-cols-2 md:grid-cols-12 items-center px-3 md:px-8">
                <div className="col-span-1 md:col-span-2">
                <span className="font-bold text-xl text-primary">AGFoods</span>
                </div>
                <div className="col-span-1 md:col-span-10 flex items-center justify-end md:justify-between">
                    <p className="font-semibold text-primary hidden lg:block">{topBarTitle}</p>
                    <div className="flex items-center border rounded-md p-1">
                        <img src={notificationLogo} alt="" className="cursor-pointer px-2" onClick="openModal('notifications')" />
                        <Link to={'profile'} className='px-2'>
                            <div className="text-sm p-[6px] bg-primary/10 text-primary font-semibold rounded-md">
                                {firstName.charAt(0)}{lastname.charAt(0)}
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="px-3 md:px-8">
                <span className="p-2">
                    <img src={menuIcon} alt="" className="h-8 w-8 bg-white shadow-sm wounded-lg lg:hidden" id="menu-icon" onClick={toggleSideBar} />
                </span>
            </div>
        </div>
    );
}

export default AdminTopBar;