import { useEffect, useState } from "react";
import NavigationHeader from "../../../Components/navigationHeader";
import Spinner from "../../../Components/spinner";
import { useDispatch, useSelector } from "react-redux";
import Buttons from "../../../Components/buttons";
import comingSoon from "../../../assets/icons/comingSoon.svg";
import investmentIcon from "../../../assets/icons/investment.svg";
import Modal from "../../../Components/modals";
import InputWithLabel from "../../../Components/inputWithLabel";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { createNewInvestmentType, listInvestmentType } from "../../../hooks/local/adminReducer";
import { Link } from "react-router-dom";

const Investment = ({ setPageTitle }) => {
    useEffect(() => {
        setPageTitle("Investments");
        document.title = "Investments | Agro-Allied";
        document.querySelector('meta[name="description"]').content = "Explore investment opportunities, manage your investments, and monitor your portfolio seamlessly with Agro-Allied.";
    }, [setPageTitle]);
    const dispatch = useDispatch();
    const investmentTypeList = useSelector((state) => state.admin.investmentTypes);
    const [investmentTypeModal, setInvestmentTypeModal] = useState(false);
    const addNewInvestmentType = useFormik({
        initialValues: {
            investmentName: ""
        },
        validationSchema: Yup.object({
            investmentName: Yup.string().required("Investment Name cannot be empty")
        }),
        onSubmit: async (values) => {
            const { investmentName } = values;
            let investmentTypeData = { investmentName };
            const { payload } = await dispatch(createNewInvestmentType(investmentTypeData));
            if (payload.statusCode === "200") {
                await dispatch(listInvestmentType());
                setInvestmentTypeModal(false);
            }
        }

    })
    return (
        <div className="col-span-10">
            <Spinner loading={useSelector((state) => state.admin).loading} />
            <div className="md:flex md:justify-between">
                <NavigationHeader title={'Available Investments'} />
                <div className="mt-10 md:mt-0">
                    <Buttons btnText={'Create new investment'} btnType={'primary'} onClick={() => { setInvestmentTypeModal(true) }} />
                </div>
            </div>
            {
                investmentTypeList.length > 0
                    ? (
                        <div className="grid gap-4 my-10 grid-cols-2 lg:grid-cols-3">
                            {investmentTypeList.map((val, key) => {
                                return (
                                    <Link to={`/management/single_investment_type/${btoa(val.investmentTypeId)}/${btoa(val.investmentName)}  `}>
                                        <div className="hover:bg-primary/5 hover:text-primary transition-all border py-8 px-4 md:px-8 rounded-lg grid md:flex items-center gap-4" key={key}>
                                            <span className="w-fit"><img src={investmentIcon} alt="" className="h-5" /></span>
                                            <p className="truncate capitalize font-medium">{val.investmentName}</p>
                                        </div>
                                    </Link>
                                )
                            })}
                        </div>
                    )
                    :
                    (
                        <div className="grid gap-4 mb-10">
                            <div className="w-full grid justify-center">
                                <img src={comingSoon} alt="" />
                            </div>
                            <p className="text-center text-lg font-semibold text-primary">No investments available yet</p>
                        </div>
                    )
            }

            <Modal isVisible={investmentTypeModal} onClose={() => { setInvestmentTypeModal(false) }}>
                <p className="text-primary font-medium">Complete the form to create a new investment type.</p>
                <form className="grid gap-6 mt-4" onSubmit={addNewInvestmentType.handleSubmit}>
                    <InputWithLabel labelName={'Investment Name'}
                        inputType={'text'}
                        inputName={'investmentName'}
                        inputValue={addNewInvestmentType.values.investmentName}
                        inputOnBlur={addNewInvestmentType.handleBlur}
                        inputOnChange={addNewInvestmentType.handleChange}
                        inputError={addNewInvestmentType.errors.investmentName && addNewInvestmentType.touched.investmentName ? addNewInvestmentType.errors.investmentName : null} />
                    <Buttons btnText={'Continue'} btnType={'primary'} type={'submit'} />
                </form>
            </Modal>
        </div>
    );
}

export default Investment;