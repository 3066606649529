import AuthHeaders from "../Components/authHeader";
import heroImg from "../assets/images/hero-illustration.svg";
import Buttons from "../Components/buttons";
import { Link } from "react-router-dom";
import checkIcon from "../assets/icons/check.svg";
import loginIcon from "../assets/icons/login.svg";
const LandingPage = () => {
  return (
    <div className="relative">
      <AuthHeaders position={"absolute"} />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 min-h-screen items-center px-5 md:px-8 pt-[80px]">
        <div className="h-full flex items-end md:items-center pt-10 md:pt-0">
          <div className="px-0 md:px-10">
            <p className="font-bold text-2xl mb-2">
              Invest in what you eat!
            </p>
            <p className="mb-5">
            Support sustainable farming by investing in local agriculture. Earn returns while empowering communities.
            </p>
            <div className="grid gap-4 cursor-default">
              <div className="flex items-center text-sm gap-2">
                <img src={checkIcon} alt="" className="h-5"/>
                <span>Insured</span>
              </div>
              <div className="flex items-center text-sm gap-2">
                <img src={checkIcon} alt="" className="h-5"/>
                <span>Investment returns guaranteed</span>
              </div>
              <div className="flex items-center text-sm gap-2">
                <img src={checkIcon} alt="" className="h-5"/>
                <span>Halal</span>
              </div>
            </div>
            <div className="mt-10 grid grid-cols-1 md:flex gap-6">
              <Link to={"/createAccount"}>
                <Buttons btnText={"Create Account"} btnType={"primary"} />
              </Link>
              <Link to={"auth"}>
                <Buttons btnText={"Login"} btnType={"secondary"} />
              </Link>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <img src={heroImg} alt="" className="md:w-2/3" />
        </div>
      </div>
      
    </div>
  );
};

export default LandingPage;
