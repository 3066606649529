import { useEffect, useState } from "react";
import { useInvestmentRequest } from "../adminLayout/reusableEffect";
import CounterCard from "../../../Components/counterCard";
import Spinner from "../../../Components/spinner";
import { useDispatch, useSelector } from "react-redux";
import NavigationHeader from "../../../Components/navigationHeader";
import comingSoon from "../../../assets/icons/comingSoon.svg";
import TransactionModal from "../../../Components/transactionModal";
import LargeModal from "../../../Components/largeModal";
import pendingIcon from "../../../assets/icons/pending.svg";
import eyeIcon from "../../../assets/icons/passwordEye.svg";
import downloadIcon from "../../../assets/icons/download.svg";
import successIcon from "../../../assets/icons/success2.svg";
import rejectIcon from "../../../assets/icons/failed.svg";
import InvestmentDetailsText from "../../../Components/investmentDetailsText";
import Buttons from "../../../Components/buttons";
import Modal from "../../../Components/modals";
import { useFormik } from "formik";
import * as Yup from "yup";
import PasswordInput from "../../../Components/passwordInput";
import { updateUserInvestments } from "../../../hooks/local/adminReducer";
import { SearchTable, filterTable } from "../../../Utils/utils";

const InvestmentRequest = ({ setPageTitle }) => {
    useEffect(() => {
        setPageTitle("Investment Request");
        document.title = "Investment Request | Agro-Allied";
        document.querySelector('meta[name="description"]').content = "Track investments, gain insights, and grow wealth with Agro-Allied dashboard.";
    }, [setPageTitle]);
    const dispatch = useDispatch();
    const investmentHistoryLog = useInvestmentRequest();
    const [tabVisibility, setTabVisibility] = useState({ investmentTab: true, pendingInvestmentTab: false });
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [approvalModal, setApprovalModal] = useState(null);
    const [approvalStatus, setApprovalStatus] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [selectedPendingTransaction, setSelectedPendingTransaction] = useState(null);
    const showTab = (tabId) => {
        setTabVisibility(() => ({
            investmentTab: tabId === 'investmentTab',
            pendingInvestmentTab: tabId === 'pendingInvestmentTab',
        }))
    }
    const updateUserInvestment = useFormik({
        initialValues: {
            adminPasskey: "",
            message: "",
        },
        validationSchema: Yup.object({
            adminPasskey: Yup.string().required("Administrative Key cannot be empty").matches(/^\d{4}$/, 'Transaction Pin can only be 4 digits'),
            message: Yup.string().required("Message cannot be empty"),
        }),
        onSubmit: async (values) => {
            const { adminPasskey, message } = values;
            let investmentData = { adminPasskey, message, transactionId, approvalStatus };
            const { payload } = await dispatch(updateUserInvestments(investmentData));
            if(payload.statusCode === "200"){
                setApprovalModal(false);
            }
        }
    })
    return (
      <div className="col-span-10">
        <Spinner loading={useSelector((state) => state.admin).loading} />
        <NavigationHeader title={"Investment Request Details"} />
        <div className="mt-10 flex gap-4 mb-4 font-medium">
          <span
            className={`py-3 px-5 text-xs rounded-md ${
              tabVisibility.investmentTab ? 'bg-primary text-white cursor-default' : 'border border-black/30 text-black/40 cursor-pointer'
            }`}
            onClick={() => showTab("investmentTab")}
          >
            Investment Details
          </span>
          <span
            className={`py-3 px-5 text-xs rounded-md ${
              tabVisibility.pendingInvestmentTab ? 'bg-primary text-white cursor-default' : 'border border-black/30 text-black/40 cursor-pointer'
            }`}
            onClick={() => showTab("pendingInvestmentTab")}
          >
            Pending Investment
          </span>
        </div>

        <div
          id="investmentTab"
          className={tabVisibility.investmentTab ? "" : "hidden"}
        >
            {investmentHistoryLog?.investmentList?.length > 0 ?
          <div className="flex justify-between mb-5">
            <div className="flex items-center gap-2">
              <label for="statusFilter" className="block text-sm font-medium">
                Showing :
              </label>
              <select
                id="statusFilter"
                onChange={() => filterTable(6)}
                className="text-sm focus:outline-none focus:border-none "
              >
                <option value="All">All Investment</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>
            <div className="">
              <input
                type="search"
                name=""
                id="searchInput"
                className="p-2 bg-[#f8f8f880] focus:outline focus:outline-primary border text-sm rounded w-full placeholder:text-xs"
                onInput={SearchTable}
                placeholder="Search History..."
              />
            </div>
          </div> :
          null
          }
          <div class="grid grid-cols-1 md:grid-cols-3 border rounded-md p-2">
            <CounterCard
              title={"Total Invested Amount"}
              number={`\u20A6${
                investmentHistoryLog.investmentSummary?.totalInvestedAmount
                  ? investmentHistoryLog.investmentSummary.totalInvestedAmount
                  : ""
              }`}
            />
            <CounterCard
              title={"Today Invested Amount"}
              number={`\u20A6${
                investmentHistoryLog.investmentSummary?.todayInvestedAmount
                  ? investmentHistoryLog.investmentSummary.todayInvestedAmount
                  : ""
              }`}
              addedStyle={"border-x-0 border-y md:border-x md:border-y-0"}
            />
            <CounterCard
              title={"Total Investment"}
              number={
                investmentHistoryLog.investmentSummary?.totalInvestment
                  ? investmentHistoryLog.investmentSummary.totalInvestment
                  : ""
              }
            />
          </div>

          {investmentHistoryLog?.investmentList?.length > 0 ? (
            <div className="overflow-x-scroll mt-10">
              <table
                className="min-w-full rounded-md overflow-hidden text-sm"
                id="dataTable"
              >
                <thead className="bg-primary/10 text-primary">
                  <tr>
                    <th className="pl-5 pr-3">S/N</th>
                    <th className="px-6 py-4 text-start">
                      <p className="truncate w-[180px]">Customer Name</p>
                    </th>
                    <th className="px-3 py-4 text-start">
                      <p className="truncate w-[180px]">Portfolio</p>
                    </th>
                    <th className="px-3 py-4 text-start">
                      <p className="truncate w-[150px]">Amount Invested</p>
                    </th>
                    <th className="px-3 py-4 text-start ">
                      <p className="truncate w-[100px]">Date Invested</p>
                    </th>
                    <th className="px-3 py-4 text-start">
                      <p className="truncate w-[80px]">Status</p>
                    </th>
                    <th className="px-3 py-4 text-start">
                      <p className="truncate w-[10px]"></p>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {investmentHistoryLog.investmentList.map((val, key) => {
                    const statusColor =
                      val.status === "Active"
                        ? "text-primary"
                        : val.status === "Pending"
                        ? "text-yellow-500"
                        : "text-red-500";
                    return (
                      <tr
                        key={val.id || key}
                        className="odd:bg-[#F9F9F9] border-t-8 border-t-white"
                        onClick={() => setSelectedTransaction(val)}
                      >
                        <td className="pl-5 pr-3 py-4 text-center">
                          <p>{key + 1}</p>
                        </td>
                        <td className="px-6 py-4">
                          <p className="truncate w-[180px]">
                            {val.userData?.firstName} {val.userData?.lastname}
                          </p>
                        </td>
                        <td className="px-3 py-4">
                          <p className="truncate w-[180px]">
                            {val.investmentName}
                          </p>
                        </td>
                        <td className="px-3 py-4 ">
                          <p className="truncate w-[150px]">
                            &#8358;{val.amount}
                          </p>
                        </td>
                        <td className="px-3 py-4 ">
                          <p className="truncate w-[100px]">{val.dateBooked}</p>
                        </td>
                        <td className="px-3 py-4">
                          <p
                            className={`truncate w-[80px] font-medium ${statusColor}`}
                          >
                            {val.status}
                          </p>
                        </td>
                        <td className="px-3 py-4 hover:scale-105">
                          <button onClick={() => setSelectedTransaction(val)}>
                            <img src={eyeIcon} alt="" className="h-5"/>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                  {selectedTransaction && (
                    <TransactionModal
                      isVisible={selectedTransaction !== null}
                      onClose={() => setSelectedTransaction(null)}
                    >
                      <div className="rounded-xl overflow-hidden relative">
                        <div className="w-full h-60 bg-primary/10 border overflow-hidden text-center flex items-center">
                          <div className="w-full">
                            <div className="w-full flex justify-center mb-4">
                              <img
                                src={
                                  selectedTransaction.status === "Active"
                                    ? successIcon
                                    : selectedTransaction.status === "Pending"
                                    ? pendingIcon
                                    : rejectIcon
                                }
                                alt=""
                              />
                            </div>
                            <p className="capitalize text-sm font-medium">
                              {selectedTransaction.transactionType}
                            </p>
                            <p className="text-lg font-semibold text-primary">
                              &#8358;<span>{selectedTransaction.amount}</span>
                            </p>
                            <p className="text-xs font-medium">
                              Investment for{" "}
                              <span>"{selectedTransaction.investmentName}"</span>
                            </p>
                          </div>
                        </div>
                        <div className="w-full h-60 bg-primary text-white flex items-center">
                          <div className="w-full px-4 text-xs grid gap-4">
                            <div className="flex justify-between pb-1 border-b border-b-white/10">
                              <div className="font-medium">ROI:</div>
                              <div className="font-bold">
                                <span className="">
                                  {selectedTransaction.roi}%
                                </span>
                              </div>
                            </div>

                            <div className="flex justify-between pb-1 border-b border-b-white/10">
                              <div className="font-medium">Date Invested:</div>
                              <div className="font-bold">
                                {selectedTransaction.dateBooked}
                              </div>
                            </div>
                            <div className="flex justify-between pb-1 border-b border-b-white/10">
                              <div className="font-medium">End Date:</div>
                              <div className="font-bold">
                                {selectedTransaction.investmentEndDate}
                              </div>
                            </div>
                            <div className="flex justify-between pb-1 border-b border-b-white/10">
                              <div className="font-medium">Status:</div>
                              <div className="font-bold">
                                <span>{selectedTransaction.status}</span>
                              </div>
                            </div>
                            <div className="flex justify-between pb-1 border-b border-b-white/10">
                              <div className="font-medium pr-2">
                                Payment Reference Number:
                              </div>
                              <div className="font-bold">
                                <span>
                                  {selectedTransaction.referenceNumber}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="w-full h-full absolute top-0 flex items-center">
                          <div className="h-10 w-full flex justify-between">
                            <div className="h-10 w-5 rounded-r-full bg-white"></div>
                            <div className="h-10 w-5 rounded-l-full bg-white"></div>
                          </div>
                        </div>
                      </div>
                    </TransactionModal>
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="grid gap-4 my-16">
              <div className="w-full grid justify-center">
                <img src={comingSoon} alt="" />
              </div>
              <p className="text-center font-semibold text-primary">
                No Investments available yet
              </p>
            </div>
          )}
        </div>

        <div
          id="pendingInvestmentTab"
          className={tabVisibility.pendingInvestmentTab ? "" : "hidden"}
        >
          <div class="grid grid-cols-1 md:grid-cols-3 border rounded-md p-2">
            <CounterCard
              title={"Today Investment"}
              number={
                investmentHistoryLog.investmentSummary?.todayInvestment
                  ? investmentHistoryLog.investmentSummary.todayInvestment
                  : ""
              }
            />
            <CounterCard
              title={"Total Pending Investment"}
              number={
                investmentHistoryLog.investmentSummary?.totalPendingInvestment
                  ? investmentHistoryLog.investmentSummary
                      .totalPendingInvestment
                  : ""
              }
              addedStyle={"border-x-0 border-y md:border-x md:border-y-0"}
            />
            <CounterCard
              title={"Today Pending Investment"}
              number={
                investmentHistoryLog.investmentSummary?.todayPendingInvestment
                  ? investmentHistoryLog.investmentSummary
                      .todayPendingInvestment
                  : ""
              }
            />
          </div>

          {investmentHistoryLog?.pendingInvestmentList?.length > 0 ? (
            <div className="overflow-x-scroll mt-10">
              <table
                className="min-w-full rounded-md overflow-hidden text-sm"
                id="dataTable"
              >
                <thead className="bg-primary/10 text-primary">
                  <tr>
                    <th className="pl-5 pr-3">S/N</th>
                    <th className="px-6 py-4 text-start">
                      <p className="truncate w-[180px]">Customer Name</p>
                    </th>
                    <th className="px-3 py-4 text-start">
                      <p className="truncate w-[180px]">Portfolio</p>
                    </th>
                    <th className="px-3 py-4 text-start">
                      <p className="truncate w-[150px]">Amount Invested</p>
                    </th>
                    <th className="px-3 py-4 text-start ">
                      <p className="truncate w-[100px]">Date Invested</p>
                    </th>
                    <th className="px-3 py-4 text-start">
                      <p className="truncate w-[80px]">Status</p>
                    </th>
                    <th className="px-3 py-4 text-start">
                      <p className="truncate w-[10px]"></p>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {investmentHistoryLog.pendingInvestmentList.map(
                    (val, key) => {
                      const statusColor =
                        val.status === "Active"
                          ? "text-primary"
                          : val.status === "Pending"
                          ? "text-yellow-500"
                          : "text-red-500";
                      return (
                        <tr
                          key={val.id || key}
                          className="odd:bg-[#F9F9F9] border-t-8 border-t-white"
                          onClick={() => setSelectedPendingTransaction(val)}
                        >
                          <td className="pl-5 pr-3 py-4 text-center">
                            <p>{key + 1}</p>
                          </td>
                          <td className="px-6 py-4">
                            <p className="truncate w-[180px]">
                              {val.userData?.firstName} {val.userData?.lastname}
                            </p>
                          </td>
                          <td className="px-3 py-4">
                            <p className="truncate w-[180px]">
                              {val.investmentName}
                            </p>
                          </td>
                          <td className="px-3 py-4 ">
                            <p className="truncate w-[150px]">
                              &#8358;{val.amount}
                            </p>
                          </td>
                          <td className="px-3 py-4 ">
                            <p className="truncate w-[100px]">
                              {val.dateBooked}
                            </p>
                          </td>
                          <td className="px-3 py-4">
                            <p
                              className={`truncate w-[80px] font-medium ${statusColor}`}
                            >
                              {val.status}
                            </p>
                          </td>
                          <td className="px-3 py-4 hover:scale-105">
                            <button
                              onClick={() => setSelectedPendingTransaction(val)}
                            >
                              <img src={eyeIcon} alt="" className="h-5"/>
                            </button>
                          </td>
                        </tr>
                      );
                    }
                  )}
                  {selectedPendingTransaction && (
                    <LargeModal
                      isVisible={selectedPendingTransaction !== null}
                      onClose={() => setSelectedPendingTransaction(null)}
                    >
                      <div className="flex justify-between items-center mb-6 mt-3">
                        <p className="text-primary font-bold text-[16px]"> Investment details </p> 
                        <button type="" onClick={() => { window.open(  selectedPendingTransaction.paymentFile, "_blank" ); }}
                            className="border-[1.5px] border-primary text-primary text-xs font-medium rounded w-fit flex gap-4 items-center px-4 py-3"
                          >
                            <img src={downloadIcon} className="h-5" alt="" />
                            <span>Download receipt</span>
                         </button> 
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"> 
                          <InvestmentDetailsText
                            title={"Customer fullname:"}
                            text={
                              selectedPendingTransaction.userData.lastname +
                              " " +
                              selectedPendingTransaction.userData.firstName
                            }
                          />
                          <InvestmentDetailsText
                            title={"Gender:"}
                            text={selectedPendingTransaction.userData.gender}
                          />
                          <InvestmentDetailsText
                            title={"Email address:"}
                            text={
                              selectedPendingTransaction.userData.emailAddress
                            }
                          />
                          <InvestmentDetailsText
                            title={"Next of kin:"}
                            text={
                              selectedPendingTransaction.userData.nextOfKinName
                            }
                          />
                          <InvestmentDetailsText
                            title={"Next of kin phone number:"}
                            text={
                              selectedPendingTransaction.userData.nextOfKinPhone
                            }
                          />
                           <InvestmentDetailsText
                            title={"Phone number:"}
                            text={
                              selectedPendingTransaction.userData.phoneNumber
                            }
                          />
                          <div className="md:col-span-2">
                            <InvestmentDetailsText
                              title={"Home address:"}
                              text={selectedPendingTransaction.userData.address}
                            />
                          </div>
                          <InvestmentDetailsText
                            title={"BVN:"}
                            text={selectedPendingTransaction.userData.bvn === "" ? <span className="text-red-500 text-xs">No BVN record</span> : selectedPendingTransaction.userData.bvn }
                          />
                          <InvestmentDetailsText
                            title={"Next of kin address:"}
                            text={
                              selectedPendingTransaction.userData
                                .nextOfKinAddress
                            }
                          />
                          <InvestmentDetailsText
                            title={"Payment reference number:"}
                            text={selectedPendingTransaction.referenceNumber}
                          />
                          <InvestmentDetailsText
                            title={"Investment name:"}
                            text={selectedPendingTransaction.investmentName}
                          />
                          <InvestmentDetailsText
                            title={"Date booked:"}
                            text={selectedPendingTransaction.dateBooked}
                          />
                          <InvestmentDetailsText
                            title={"Investment end date:"}
                            text={selectedPendingTransaction.investmentEndDate}
                          />
                          <InvestmentDetailsText
                            title={"ROI:"}
                            text={selectedPendingTransaction.roi + "%"}
                          />
                          <InvestmentDetailsText
                            title={"Amount invested:"}
                            text={`\u20A6${selectedPendingTransaction.amount}`}
                          /> 
                      </div>
                      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-10">
                        <div className="lg:col-span-2">
                          <Buttons
                            btnText={"Approve Investment"}
                            btnType={"primary"}
                            onClick={() => {
                              setApprovalModal(true);
                              setSelectedPendingTransaction(null);
                              setApprovalStatus("0");
                              setTransactionId(
                                selectedPendingTransaction.transactionId
                              );
                            }}
                          />
                        </div>
                        <div>
                          <Buttons
                            btnText={"Reject Investment"}
                            btnType={"delete"}
                            onClick={() => {
                              setApprovalModal(true);
                              setSelectedPendingTransaction(null);
                              setApprovalStatus("2");
                              setTransactionId(
                                selectedPendingTransaction.transactionId
                              );
                            }}
                          />
                        </div>
                      </div>
                    </LargeModal>
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="grid gap-4 my-16">
              <div className="w-full grid justify-center">
                <img src={comingSoon} alt="" />
              </div>
              <p className="text-center font-semibold text-primary">
                No Pending Investment Available yet
              </p>
            </div>
          )}
        </div>

        <Modal
          isVisible={approvalModal}
          onClose={() => {
            setApprovalModal(false);
            updateUserInvestment.resetForm();
          }}
        >
          <form onSubmit={updateUserInvestment.handleSubmit}>
            <p className={`text-primary font-medium pb-4`}>
              {approvalStatus === "0" ? "Approve" : "Reject"} Investment
            </p>
            <PasswordInput
              labelName={"Admin Transaction Pin"}
              inputName={"adminPasskey"}
              inputOnBlur={updateUserInvestment.handleBlur}
              inputOnChange={updateUserInvestment.handleChange}
              inputValue={updateUserInvestment.values.adminPasskey}
              inputError={
                updateUserInvestment.errors.adminPasskey &&
                updateUserInvestment.touched.adminPasskey
                  ? updateUserInvestment.errors.adminPasskey
                  : null
              }
            />
            <div className="grid py-5">
              <span className="text-sm font-medium text-primary">Message:</span>
              <textarea
                rows="5"
                cols="5"
                name="message"
                className="p-3 bg-[#f8f8f8] border text-sm rounded"
                onBlur={updateUserInvestment.handleBlur}
                onChange={updateUserInvestment.handleChange}
                value={updateUserInvestment.values.message}
              />

              <code className="text-red-500 text-xs">
                {updateUserInvestment.touched.message &&
                updateUserInvestment.errors.message
                  ? updateUserInvestment.errors.message
                  : null}{" "}
              </code>
            </div>
            <Buttons btnText={"Continue"} btnType={"primary"} type={"submit"} />
          </form>
        </Modal>
      </div>
    );
}

export default InvestmentRequest;