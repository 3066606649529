import { useEffect, useState } from "react";
import { currencyFormat, getPeriodOfDay } from "../../../Utils/utils";
import eyeIcon from "../../../assets/icons/eyeFill.svg";
import creditSvg from "../../../assets/icons/credit.svg";
import debitSvg from "../../../assets/icons/debit.svg";
import { useSelector } from "react-redux";
import { useUserBalanceSummary, useUserTransactionHistory } from "../userLayout/reusableEffects";
import Spinner from "../../../Components/spinner";
import { Link } from "react-router-dom";

const UserDashboard = ({ setPageTitle }) => {
  const [timeOfTheDay, setTimeOfTheDay] = useState("");
  const [showROIBalance, setShowROIBalance] = useState(false);
  const [showInvestmentBalance, setShowInvestmentBalance] = useState(false);
  const userTransactionHistory = useUserTransactionHistory();
  const userBalanceSummary = useUserBalanceSummary();

  const roiBalance = userBalanceSummary?.roiBalance ? currencyFormat(userBalanceSummary.roiBalance) : '0.00';
  const investmentBalance = userBalanceSummary?.investmentBalance ? currencyFormat(userBalanceSummary.investmentBalance) : '0.00';

  useEffect(() => {
    setPageTitle("Dashboard");
    document.title = "Dashboard | Agro-Allied";
    document.querySelector('meta[name="description"]').content = "Track investments, gain insights, and grow wealth with Agro-Allied dashboard.";
    setTimeOfTheDay(getPeriodOfDay);

  }, [setPageTitle]);



  return (

    <div className="col-span-10 md:mx-4">
      <Spinner loading={useSelector((state) => state.user).loading} />
      <p>Good {timeOfTheDay}<span id="time-period"></span>, <span className="font-semibold">{useSelector((state) => state.user.userSessionData).firstname}</span></p>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pt-5">
          <div className="border rounded-md hover:bg-primary hover:text-white p-5 md:p-8 transition-all">
           <div className="">
            <div>
              <p className="text-sm font-semibold text-black/40">ROI Balance</p>
              <div className="text-lg">
                <span className="font-medium">NGN </span>
                {showROIBalance ?
                  (<><span className="font-medium">{roiBalance.wholeNumber}</span>.<span>{roiBalance.decimalPart}</span></>)
                  : (<span className="font-medium ps-2">*,***,***,***</span>)
                }
              </div>
            </div>
            <div className="flex justify-end">
              <button className="flex items-center gap-2 bg-primary text-white border border-white/30 p-4  rounded-md" onClick={() => { setShowROIBalance(!showROIBalance) }}>
                <img src={eyeIcon} alt="" className="h-4"/>
                <span className="text-xs font-medium">{showROIBalance ? 'Hide Balance' : 'Show balance'}</span>
              </button>
            </div>
           </div>
          </div>
          <div className="border rounded-md hover:bg-primary hover:text-white p-5 md:p-8 transition-all">
           <div className="">
           <div>
              <p className="text-sm font-semibold text-black/40">Investment Balance</p>
              <div className="text-lg">
                <span className="font-medium">NGN </span>
                {showInvestmentBalance ?
                  (<><span className="font-medium">{investmentBalance.wholeNumber}</span>.<span>{investmentBalance.decimalPart}</span></>)
                  : (<span className="font-medium ps-2">*,***,***,***</span>)
                }
              </div>
            </div>
            <div className="flex justify-end">
              <button className="flex items-center gap-2 bg-primary text-white border border-white/30 p-4 rounded-md" onClick={() => { setShowInvestmentBalance(!showInvestmentBalance) }}>
                <img src={eyeIcon} alt="" className="h-4"/>
                <span className="text-xs font-medium">{showInvestmentBalance ? 'Hide Balance' : 'Show balance'}</span>
              </button>
            </div>
           </div>
          </div>
        </div>
      <div className="grid grid-cols-1 mt-8">
        <div>
          <div className="flex justify-between items-center">
            <p className="text-primary font-semibold">Recent transactions:</p>
            {
              userTransactionHistory.length > 0 ? 
              <Link to={"/transactions"}><span className="text-primary font-medium underline">View all</span></Link>
              :
              null
            }

          </div>
          <div className=" px-4 py-8 rounded-lg mt-4 grid gap-4 bg-black/1">
            {
              userTransactionHistory.length > 0 ?
                userTransactionHistory.slice(0,5).map((val, key) => {
                  const transactionIcon = (val.transactionType === "Book Investment") ? creditSvg : debitSvg;
                  const statusColor = (val.status === "Approved") ? 'text-primary' :(val.status === "Pending") ?'text-yellow-500' :'text-red-500';
                  return (
                    <div key={key} className="flex gap-4 ">
                      <div><img src={transactionIcon} alt="" /></div>
                      <div className="grid grid-cols-12 w-full font-medium text-sm items-center border-b">
                        <div className="col-span-6 md:col-span-4 text-start">{val.transactionType}</div>
                        <div className="col-span-6 md:col-span-3 text-start">{val.insertedDt}</div>
                        <div className="hidden md:block col-span-3 text-center">&#8358;<span>{val.amount}</span></div>
                        <div className={`${statusColor} font-bold hidden md:block col-span-2 text-end`}>{val.status}</div>
                      </div>
                    </div>
                  )
                })
                :
                (
                  <div className="text-center pt-3">No Transaction Available</div>
                )
            }


          </div>
        </div>
      </div>


    </div>
  );
}

export default UserDashboard;