import { useEffect, useState } from "react";
import NavigationHeader from "../../../Components/navigationHeader";
import { useInvestmentTypeList, useInvestmentTypes } from "../userLayout/reusableEffects";
import comingSoon from "../../../assets/icons/comingSoon.svg";
import Spinner from "../../../Components/spinner";
import { useSelector } from "react-redux";
import Modal from "../../../Components/modals";
import { Link } from "react-router-dom";

const Investment = ({ setPageTitle }) => {
  useEffect(() => {
    setPageTitle("Investments");
    document.title = "Investments | Agro-Allied";
    document.querySelector('meta[name="description"]').content = "Explore investment opportunities, manage your investments, and monitor your portfolio seamlessly with Agro-Allied.";
  }, [setPageTitle]);

  const investmentTypes = useInvestmentTypes();
  const [selectedInvestmentType, setSelectedInvestmentType] = useState(null);
  const [selectedInvestmentName, setSelectedInvestmentName] = useState(null);
  const [selectedInvestmentDetails, setSelectedInvestmentDetails] = useState(null);
  const investmentTypeDetails = useInvestmentTypeList(selectedInvestmentType);
  useEffect(() => {
    if (investmentTypes.length > 0) {
      setSelectedInvestmentType(investmentTypes[0].investmentTypeId);
      setSelectedInvestmentName(investmentTypes[0].investmentName);
    }
  }, [investmentTypes])

  const userSelectedInvestmentType = (investmentTypeId, investmentTypeName) => {
    setSelectedInvestmentName(investmentTypeName);
    setSelectedInvestmentType(investmentTypeId);
  }
  return (
    <div className="col-span-10 bg-white md:px-5 rounded-lg">
      <Spinner loading={useSelector((state) => state.user).loading} />
      <NavigationHeader title={"Investments"} />
      {investmentTypes.length > 0 ? (
        <div>
          <div className="grid grid-cols-1 gap-6 my-6">
            <div>
              <p className="text-primary text-lg font-semibold">
                Choose an investment
              </p>
              <p className="text-sm">Select from list investment available</p>
            </div>
            <div
              id="tabs"
              className="flex gap-3 overflow-x-scroll whitespace-nowrap text-sm text-white"
            >
              {investmentTypes.map((val, key) => {
                return (
                  <button
                    className={`tab-btn py-3 px-5 text-xs rounded-md  ${selectedInvestmentType === val.investmentTypeId
                      ? "bg-primary text-white cursor-default"
                      : "border border-black/30 text-black/40 cursor-pointer"
                      }`}
                    onClick={() =>
                      userSelectedInvestmentType(
                        val.investmentTypeId,
                        val.investmentName
                      )
                    }
                    key={key}
                  >
                    {val.investmentName}{" "}
                  </button>
                );
              })}
            </div>
          </div>
          {selectedInvestmentType && (
            <div className="tab-content rounded-md">
              {investmentTypeDetails.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6">
                  {investmentTypeDetails.map((val, key) => {
                    return (
                      <div
                        className="grid bg-[#f8f8f8] p-2 rounded mb-6 "
                        key={key}
                      >
                        <div className="grid gap-4">
                          <img
                            src={val.investmentImage}
                            alt=""
                            className="rounded object-cover w-full aspect-video"
                          />
                          <div className="flex justify-between gap-1">
                            <span className="text-sm font-semibold text-primary">
                              {" "}
                              {val.investmentName}{" "}
                            </span>
                            <div className="flex items-center gap-1 text-xs">
                              <span>Status:</span>
                              <span
                                className={`text-xs ${val.status === "Active"
                                  ? "text-primary"
                                  : "text-red-500"
                                  } font-medium`}
                              >
                                {val.status}{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="grid mt-4">
                          <button
                            className="text-xs px-6 py-4 rounded-md bg-primary text-white w-full"
                            onClick={() =>
                              setSelectedInvestmentDetails(val)
                            }
                          >
                            {val.status === "Active"
                              ? "Invest Now"
                              : "View More"}
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div>
                  <div className="grid gap-4 my-16">
                    <div className="w-full grid justify-center">
                      <img src={comingSoon} alt="" />
                    </div>
                    <p className="text-center text-lg font-semibold text-primary">
                      No investments available yet for{" "}
                      {selectedInvestmentName}
                    </p>
                    <p className="text-center">
                      We are working round the clock to serve you better by
                      introducing great features. <br /> Stay tuned.
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}

          {selectedInvestmentDetails && (
            <div>
              <Modal
                isVisible={selectedInvestmentDetails !== null}
                onClose={() => setSelectedInvestmentDetails(null)}
              >
                <div className="grid gap-2">
                  <div className="rounded-lg overflow-hidden">
                    <img
                      src={selectedInvestmentDetails.investmentImage}
                      alt=""
                      className="h-48 w-full object-cover"
                    />
                  </div>
                  <div>
                    <p className="font-semibold">
                      {selectedInvestmentDetails.investmentName}
                    </p>
                    <p className="text-sm text-[#c4c4c4]">
                      {selectedInvestmentDetails.description}
                    </p>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 text-[#c4c4c4] mt-6 gap-4 md:gap-6">
                  <div>
                    <p className="text-xs text-black">Minimum Investment</p>
                    <p className="font-semibold text-black">
                      &#8358;{selectedInvestmentDetails.minimumInvestment}
                    </p>
                  </div>
                  <div>
                    <p className="text-xs text-black">Maximum Investment</p>
                    <p className="font-semibold text-black">
                      &#8358;{selectedInvestmentDetails.maximumInvestment}
                    </p>
                  </div>
                  <div>
                    <p className="text-xs text-black">Start Date</p>
                    <p className="font-semibold text-black">
                      &#8358;{selectedInvestmentDetails.startDate}
                    </p>
                  </div>
                  <div>
                    <p className="text-xs text-black">End Date</p>
                    <p className="font-semibold text-black">
                      &#8358;{selectedInvestmentDetails.endDate}
                    </p>
                  </div>
                  <div>
                    <p className="text-xs text-black">ROI</p>
                    <p className="font-semibold text-black">
                      {selectedInvestmentDetails.roi} %
                    </p>
                  </div>
                  <div>
                    <p className="text-xs text-black">Current investors</p>
                    <p className="font-semibold text-black">
                      {selectedInvestmentDetails.noOfPeople}
                    </p>
                  </div>
                </div>

                <p className={`text-xs mt-6 mb-10 font-semibold w-fit p-2 rounded ${selectedInvestmentDetails.status === "Active"
                  ? "bg-primary/10"
                  : "text-red-500/10"}`}>
                  Investment Status:{" "}
                  <span
                    className={`font-semibold ${selectedInvestmentDetails.status === "Active"
                      ? "text-primary/50"
                      : "text-red-500"
                      }`}
                  >
                    {selectedInvestmentDetails.status}
                  </span>
                </p>

                {selectedInvestmentDetails.status === "Active" ? (
                  <Link
                    to={`/invest_now/${btoa(selectedInvestmentDetails.id)}`}
                  >
                    <button class="bg-primary text-center p-4 text-sm rounded text-white w-full">
                      Invest now
                    </button>
                  </Link>
                ) : (
                  ""
                )}
              </Modal>
            </div>
          )}
        </div>
      ) : (
        <div id="" className="">
          <div className="grid gap-4 my-16">
            <div className="w-full grid justify-center">
              <img src={comingSoon} alt="" />
            </div>
            <p className="text-center text-lg font-semibold text-primary">
              No investments available yet
            </p>
            <p className="text-center">
              We are working round the clock to serve you better by
              introducing great features. <br /> Stay tuned.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Investment;